/**
 * File responsible for all the UI and actions for Settings>Users page - `/app/settings/users`.
 */

import { Button, Col, Row, Typography, Modal } from 'antd';
import {
    get,
    isEmpty,
    map
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    useEffect,
    useState,
    Suspense,
    lazy,
    useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FontAwesome from '../../components/common/FontAwesome';
import { withPageViewHandler } from '../../components/common/PageViewHandler';
import VirtualizedList from '../../components/common/VirtualizedList';
import SupportTicketItemComponent from '../../components/support/SupportTicketItemComponent';
import {
    SUPPORT_TICKET_LISTS_PAGE,
} from '../../config/tableAndPageConstants';
import { ApplicationState } from '../../store';
import { CompanyUserRole } from '../../store/companies/types';
import {
    computeTableScroll,
    getPopoverContainer
} from '../../utils/commonFunctions';
import {
    DynamicObject
} from '../../utils/commonInterfaces';
import {
    getSupportTicketListsRequestAction,
    getSupportTicketDataRequestAction,
    setSelectedSupportTicketIdRequestAction
} from '../../store/supportTickets/actions';
import {
    SupportTicket,
    GetSupportTicketRequestPayload
} from '../../store/supportTickets/types';

const SupportTicketItemDetailsDrawerComponent = lazy(
    () => import('../../components/support/SupportTicketItemDetailsDrawerComponent')
);

const { Title } = Typography;

interface IProps {};

let resetTableScroll = false;

const SupportTicketListsManagementPage: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const drawerRef: any = useRef(null);
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(0);
    const [supportTicketLists,setSupportTicketLists] = useState<SupportTicket[]>([]);
    const [fetchLoading, setFetchLoading] = useState<boolean>(true);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [supportTicketDetailsDrawer, setSupportTicketDetailsDrawer] = useState<{
        visible: boolean;
    }>({
        visible: false
    });

    /**
     * Fetch the Users from API.
     * @param currentPage - page where the scroll is at
     * @param pageSize - number of items in a page
     */
    const fetchSupportTickets = (
        currentPage = tableCurrentPage,
        pageSize = SUPPORT_TICKET_LISTS_PAGE.pageSize
    ) => {
        const payload = generatePayloadForRequest(currentPage, pageSize);
        dispatch(
            getSupportTicketListsRequestAction(payload,(res: DynamicObject) => {
                if(res.IsSuccess){
                    if(res.data){
                        map(res.data, (ticket:any) => {

                            const updatedTicket = {
                                ...ticket,
                                key: ticket.id
                            };
        
                            setSupportTicketLists(oldArray => [...oldArray, updatedTicket]);
                        });

                        setHasNextPage(res.pageData.hasNextPage);
                    }
                } else {
                    let errorMessageContent = [];
                    if (!isEmpty(res.Messages)) {
                        errorMessageContent = map(
                            res.Messages,
                            (error: string, index: number) => (
                                <div key={index}>{error}</div>
                            )
                        );

                        showModalError({
                            title: 'Error',
                            content: errorMessageContent,
                        });

                        setErrorMessage(res.Messages[0])
                    }
                }
                setFetchLoading(false)
            })
        );
    };

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetSupportTicketRequestPayload = {
            pageSize,
            currentPage
        };

        return payload;
    };

    /**
     * Common function for showing modal error.
     * @param modalObject
     */
    const showModalError = (modalObject: {}) => {
        Modal.error({
            ...modalObject,
            getContainer: () => getPopoverContainer(drawerRef),
        });
    };

    /**
     * Function that controls the page before fetch user is called.
     * This also sets the state table page that is used.
     */
    const handleFetch = () => {
        if (
            isEmpty(selectedUserCompany) ||
            fetchLoading
        )
            return;
        if (!hasNextPage) return;

        const nextPage = tableCurrentPage + 20;
        setFetchLoading(true)
        setTableCurrentPage(nextPage);
        fetchSupportTickets(nextPage);
    };

    /**
     * Function that will be called upon initial loading of page, filter and sort changes, and company switch.
     */
    const callFetchSupportLists = () => {
        if (!selectedUserCompany) return;
        setSupportTicketLists([]);
        setFetchLoading(true)
        fetchSupportTickets(0);
    };

    useEffect(callFetchSupportLists, [
        selectedUserCompany
    ]);

    /**
     * Function for populating the loading text for table.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching list of support tickets`;

        return loadingText;
    };

    /**
     * Function called when row is clicked.
     * @param record - data where the row is clicked
     * @param activeTab - kind of panel to show based on the row clicked
     */
    const onRowClick = (record: DynamicObject, activeTab?: string) => {
        dispatch(
            setSelectedSupportTicketIdRequestAction(get(record, 'id'), () => {
                setSupportTicketDetailsDrawer({
                    visible: true
                });
            })
        );
    };

    /**
     * Function called to close the invoice details drawer.
     * Clears the data saved during drawer close.
     */
    const closeSupportTicketDetailsDrawer = () => {
        setSupportTicketDetailsDrawer({
            visible: false
        });
    };

    /**
     * Function called when sync/refresh button is clicked.
     */
    const handleSupportTicketFilterRefresh = () => {
        setFetchLoading(true);
        setTableCurrentPage(0);
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        setSupportTicketLists([]);
        fetchSupportTickets(0);
    };

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>Support Ticket Lists</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
              
                {/* Action Bar */}
                <div key="action-bar-container">
                    <Row
                        type="flex"
                        justify="space-between"
                        key="action-bar-container"
                    >
                        <Col>
                            <Row type="flex">
                                <Button
                                    className="action-bar-button"
                                    type="link"
                                    disabled={fetchLoading}
                                    onClick={handleSupportTicketFilterRefresh}
                                >
                                    <FontAwesome
                                                icon={['fa', 'sync']}
                                                className="mr-8"
                                            />
                                            <span>Refresh</span>
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={supportTicketLists}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                SUPPORT_TICKET_LISTS_PAGE.pageSize,
                                SUPPORT_TICKET_LISTS_PAGE.rowHeight
                            )}
                            onRowClick={onRowClick}
                            resetTableScroll={resetTableScroll}
                            loading={fetchLoading}
                            loadingText={populateTableLoadingText()}
                            emptyText={errorMessage ? errorMessage : "No support ticket found"}
                            hasNextPage={true}
                            itemComponent={SupportTicketItemComponent}
                            itemHeight={SUPPORT_TICKET_LISTS_PAGE.rowHeight}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <SupportTicketItemDetailsDrawerComponent
                            visible={supportTicketDetailsDrawer.visible}
                            closeDrawer={closeSupportTicketDetailsDrawer}
                            refetchList={refetchListAndResetScroll}
                            getSupportTicketDataRequestAction={getSupportTicketDataRequestAction}
                        />
                    </Suspense>
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default withPageViewHandler(withRouter(SupportTicketListsManagementPage));
