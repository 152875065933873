import { DynamicObject } from "../../utils/commonInterfaces";
import { User } from "../users/types";

export enum ChangesActionTypes {
    DEALLOCATE_REQUEST = '@@credits/DEALLOCATE_REQUEST',
}

export interface ChangesState {
}

export interface ChangeLine
{
    readonly Id: string;
    readonly CompanyId: string;
    readonly CreatedDateTime: string;
    readonly Type: string;
    readonly DisplayName: string;
    readonly User: User;
    readonly Context: string;
    readonly Resource: DynamicObject;
    readonly AssociatedResource: DynamicObject;
    readonly Source: string;
    readonly Allocation: DynamicObject;
    readonly ChangeSets: DynamicObject[];
    readonly IsReversed: Boolean;
}

export interface ChangeLinesFetchedData {
    OnChangeLinesFetchedForOrganisation: {
        ChangeLines: ChangeLine[];
    };
};

export interface ChangeLinesFetchedForOrganisationPayload {
    data: ChangeLinesFetchedData;
    pageSize: number;
    currentPage: number;
}