/**
 * File responsible for all the UI and actions for Settings>Company page - General tab item - `/app/settings/company`.
 */

import { Col, Row, Tabs, Spin, Modal } from 'antd';
import { History as IHistory } from 'history';
import { get, isEmpty, map, unset } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { forwardRef, Ref, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouteLeavingGuard from '../../../../components/common/RouteLeavingGuard';
import { Company } from '../../../../store/companies/types';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../../utils/commonInterfaces';
import {
    INSTALMENT_TYPE,
    FORM_FIELDS,
    TAB_NAME
} from '../../../../constants/paymentPlanConfigs';
import CreatePaymentPlanConfigTabContent from '../../../../components/common/CreatePaymentPlanConfigTabContent';

import { PaymentPlansState, PaymentPlanConfig } from '../../..//../store/paymentPlans/types';
import { ApplicationState } from '../../../../store';
import { generateGUID, getLoadingSkeletons, getPopoverContainer } from '../../../../utils/commonFunctions';
import { createPaymentPlanConfigRequestAction } from '../../../../store/paymentPlans/actions';
import { dateFormatDDMMSlash } from '../../../../constants/dateFormats';

const { TabPane } = Tabs;
interface IProps {
    history: typeof IHistory;
    disabled: boolean;
    selectedUserCompany: Company;
    handleModalSaveResponse: (response: ResponseModalObject) => void;
    setFormHasChanges: () => void;
    form?: any;
    setSaveLoading: (
        saveLoading: boolean
    ) => void;
}
const CreatePaymentPlanConfigPage: React.FC<IProps> = forwardRef(
    (
        props: IProps,
        ref: Ref<any>
    ) => {
        const drawerRef: any = useRef(null);
        const dispatch = useDispatch();
        const { validateFields, resetFields } = props.form;
        const [paymentPlanConfigFormState, setPaymentPlanConfigFormState] = useState<PaymentPlanConfig[]>([]);
        const paymentPlansState: PaymentPlansState = useSelector(
            (state: ApplicationState) => state.paymentPlans
        );
        const { loading } = paymentPlansState;


        ///tab state
        const [paymentPlanOptions, setPaymentPlanOptions] = useState<any[]>([]);
        const [paymentPlanOptionTabId, setPaymentPlanOptionTabId] = useState(1);
        const [paymentPlanOptionKeys, setPaymentPlanOptionKeys] = useState<number[]>([]);
        const [selectedPaymentPlanOptionTab, setSelectedPaymentPlanOptionTab] = useState<string>("payment-plan-dynamic-0");


        /**
         * This is used for the Parent component wrapping this one be able to call the functions
         * inside this (save, cancel, checkForChanges).
         */
        React.useImperativeHandle(ref, () => ({
            save: () => {
                handleSave();
            },
            cancel: () => {
                handleCancel();
            },
            checkForChanges: () => {
                return checkForChanges();
            },
        }));

        /**
         * Check if there are any changes in the form (unsaved).
         */
        const checkForChanges = () => {
            let enabledSubmit: boolean = false;
            if (paymentPlanConfigFormState && paymentPlanOptions.length > 0) {
                map(paymentPlanOptions, (value) => {
                    let tabIndex: string = value.key.split('-')[3];

                    let installmentTypeHasChange: any | undefined = get(paymentPlanConfigFormState, FORM_FIELDS.NUMBER_OF_INSTALMENT + tabIndex);
                    let dateRangeHasChange: any | undefined = get(paymentPlanConfigFormState, FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE + tabIndex);
                    let fromMonthHasChange: any | undefined = get(paymentPlanConfigFormState, FORM_FIELDS.FROM_MONTH + tabIndex);
                    let paymentDayHasChange: any | undefined = get(paymentPlanConfigFormState, FORM_FIELDS.PAYMENT_DAY + tabIndex);
                    let specificPaymentDayHasChange: any | null = get(paymentPlanConfigFormState, FORM_FIELDS.SPECIFIC_PAYMENT_DAY + tabIndex);
                    let customFieldsHasChange: any[] = get(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELDS + tabIndex);
                    let depositPercentageHasChange: any[] = get(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELDS + tabIndex);
                    let depositGabHasChange: any[] = get(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELDS + tabIndex);
                    let depositAmountHasChange: any[] = get(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELDS + tabIndex);


                    if (installmentTypeHasChange
                        || depositAmountHasChange
                        || depositGabHasChange
                        || depositPercentageHasChange
                        || (customFieldsHasChange && customFieldsHasChange.length)
                        || fromMonthHasChange
                        || paymentDayHasChange
                        || specificPaymentDayHasChange
                        || dateRangeHasChange) {
                        enabledSubmit = true;
                    }


                });
            }

            return enabledSubmit;
        };

        /**
         * update form state
         */
        const updateFormStateObject = (name: string, val: any) => {
            setPaymentPlanConfigFormState((prev: any) => ({
                ...prev,
                [name]: val
            }));
        };

        /**
         * Function called when the save button is called.
         * Specific to this form only and does not affect the other child tab items
         * that are side by side with this form.
         */
        const handleSave = () => {
            validateFields((err: DynamicObject, values: DynamicObject) => {
                let paymentPlanConfigOptions: {
                    ConfigId: string;
                    Frequency?: string;
                    Instalments?: string;
                    ScheduleDates?: string[];
                    CustomFields?: { Name: string; Value: string }[] | [];
                    DepositAmount?: number;
                    DepositPercentage?: number;
                    DepositGap?: number;
                }[] = [];

                if (!err) {
                    let invalidFields: string[] = [];
                    paymentPlanOptions.map((obj) => {

                        let keyIndex = obj.key.split('-')[3];

                        let installmentType = get(paymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_TYPE + keyIndex);
                        let numberOfInstallment = get(paymentPlanConfigFormState, FORM_FIELDS.NUMBER_OF_INSTALMENT + keyIndex);
                        let customFields: { Name: string; Value: string }[] = [];
                        let scheduleDates: string[] = [];

                        //add schedule dates
                        if (numberOfInstallment != null && numberOfInstallment > 0) {
                            switch (installmentType) {
                                case INSTALMENT_TYPE.MONTHLY:
                                case INSTALMENT_TYPE.DEFAULT:
                                    scheduleDates = get(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATES + keyIndex);
                                    break;
                                case INSTALMENT_TYPE.CUSTOM:
                                    for (var i = 0; i < numberOfInstallment; i++) {
                                        let key: number[] = get(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATE_KEYS + keyIndex);
                                        let scheduleDate = get(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULED_DATE + keyIndex + key[i]).format(dateFormatDDMMSlash);

                                        if (!isEmpty(scheduleDate)) {
                                            scheduleDates.push(scheduleDate);
                                        }
                                    }
                                    break;
                            }

                        }

                        //check if schedule dates has duplicate
                        const uniqueScheduleDates = new Set(scheduleDates);
                        if (uniqueScheduleDates.size !== scheduleDates.length) {
                            invalidFields.push('Schedule dates has duplicate.');
                        }

                        //add tab custom fields
                        let customFieldTabKeys: any[] | null = get(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELD_KEYS + keyIndex);
                        if (customFieldTabKeys && customFieldTabKeys.length > 0) {
                            map(customFieldTabKeys, (val) => (
                                customFields.push({
                                    Name: get(paymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_NAME + keyIndex + val),
                                    Value: get(paymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_VALUE + keyIndex + val)
                                })
                            ));
                        }

                        //check if customer fields has duplicate
                        const uniqueCustomeFields = new Set(customFields.map(obj => JSON.stringify(obj)));
                        if (uniqueCustomeFields.size !== customFields.length) {
                            invalidFields.push('Custom feilds has duplicate.');
                        }

                        let payload: {
                            ConfigId: string;
                            Frequency?: string;
                            Instalments?: string;
                            ScheduleDates?: string[];
                            DepositAmount?: number;
                            DepositPercentage?: number;
                            DepositGap?: number;
                            CustomFields?: { Name: string; Value: string }[];
                        } = {
                            ConfigId: generateGUID(),
                            Instalments: numberOfInstallment,
                        };

                        if (customFields.length > 0) {
                            payload.CustomFields = customFields;
                        }

                        if (scheduleDates != null && scheduleDates.length > 0) {
                            payload.ScheduleDates = scheduleDates;
                        }

                        if (installmentType == INSTALMENT_TYPE.MONTHLY) {
                            payload.Frequency = INSTALMENT_TYPE.MONTHLY;
                        }

                        let depositPercentag = get(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_PERCENTAGE + keyIndex);
                        if (depositPercentag) {
                            payload.DepositPercentage = depositPercentag

                        }

                        let depositGap = get(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_GAP + keyIndex);
                        if (depositGap) {
                            payload.DepositGap = depositGap

                        }

                        let depositAmount = get(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_AMOUNT + keyIndex);
                        if (depositAmount) {
                            payload.DepositAmount = depositAmount
                        }

                        paymentPlanConfigOptions.push(payload);

                    });

                    if (invalidFields.length > 0) {
                        Modal.error({
                            title: 'Invalid values',
                            content: (<ul>
                                {map(
                                    invalidFields,
                                    (error: string) => (
                                        <li key={error}>{error}</li>
                                    )
                                )}</ul>
                            ),
                            getContainer: () => getPopoverContainer(drawerRef),
                        });
                    } else if (paymentPlanConfigOptions.length > 0) {
                        props.setSaveLoading(true);
                        const paymentPlanConfigPayload: {
                            PaymentPlanConfigurations: {},
                        } = {
                            PaymentPlanConfigurations: paymentPlanConfigOptions
                        }

                        submitPaymentPlanConfig(paymentPlanConfigPayload);
                    }
                }
            });
        };

        /**
         * Function called when cancel is clicked.
         * Resets all the changes and set the data based on the one saved in redux (the original saved data).
         */
        const handleCancel = () => {
            if (paymentPlanOptions && paymentPlanOptions.length > 0) {
                map(
                    paymentPlanOptions, (option) => {

                        let keyIndex = option.key.split('-')[3];

                        //unset fields states
                        unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATES + keyIndex);
                        unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATES_DISPLAY + keyIndex);
                        unset(paymentPlanConfigFormState, FORM_FIELDS.NUMBER_OF_INSTALMENT + keyIndex);
                        unset(paymentPlanConfigFormState, FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE + keyIndex);
                        unset(paymentPlanConfigFormState, FORM_FIELDS.FROM_MONTH + keyIndex);
                        unset(paymentPlanConfigFormState, FORM_FIELDS.PAYMENT_DAY + keyIndex);
                        unset(paymentPlanConfigFormState, FORM_FIELDS.SPECIFIC_PAYMENT_DAY + keyIndex);
                        unset(paymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_TYPE + keyIndex);
                        unset(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_GAP + keyIndex);
                        unset(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_PERCENTAGE + keyIndex);
                        unset(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_AMOUNT + keyIndex);

                        //remove custom fields
                        let keys: number[] = get(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELD_KEYS + keyIndex);
                        if (keys && keys.length > 0) {
                            map(keys, (key) => {
                                unset(paymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_NAME + keyIndex + key);
                                unset(paymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_VALUE + keyIndex + key);
                            });
                        }

                        //remove customer schedule date
                        let scheduleDatesKey: number[] = get(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATE_KEYS);
                        if (scheduleDatesKey && scheduleDatesKey.length > 0) {
                            map(scheduleDatesKey, (key) => {
                                unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULED_DATE + key);

                            });
                            unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATE_KEYS + keyIndex);

                        }

                        if (keyIndex == 0) {

                            //set initial custom field tab state
                            updateFormStateObject(TAB_NAME.CUSTOM_FIELD_ID + keyIndex, 0);
                            updateFormStateObject(TAB_NAME.CUSTOM_FIELDS + keyIndex, []);
                            updateFormStateObject(TAB_NAME.CUSTOM_FIELD_KEYS + keyIndex, []);
                            updateFormStateObject(TAB_NAME.SELECTED_CUSTOM_FIELD + keyIndex, '');
                        } else {

                            //remove state
                            unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATE_KEYS + keyIndex);
                            unset(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELDS + keyIndex);
                            unset(paymentPlanConfigFormState, TAB_NAME.SELECTED_CUSTOM_FIELD + keyIndex);
                            unset(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELD_KEYS + keyIndex);
                            unset(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELD_ID + keyIndex);
                        }

                    });

                //set init tab option
                setSelectedPaymentPlanOptionTab('payment-plan-dynamic-0');
                let saveFirstOption: any[] = [paymentPlanOptions[0]];
                setPaymentPlanOptions(saveFirstOption);
            }

            resetFields();
            props.setFormHasChanges();

        };

        const submitPaymentPlanConfig = (payload: any) => {
            dispatch(
                createPaymentPlanConfigRequestAction(payload, props.handleModalSaveResponse)
            );
        }

        const updatePaymentPlanOptionPanes = () => {
            let tabPane: any[] = [];
            if (paymentPlanConfigFormState.length == 0) {
                const tabOption = {
                    key: `payment-plan-dynamic-0`,
                    title: 'Payment Plan ',
                    component: CreatePaymentPlanConfigTabContent,
                    closable: false
                }

                tabPane.push(tabOption);
                setPaymentPlanOptions(tabPane);
            }
        }
        useEffect(updatePaymentPlanOptionPanes, [paymentPlanConfigFormState]);

        const addPaymentPlanOption = () => {
            const tabOption = {
                key: `payment-plan-dynamic-${paymentPlanOptionTabId}`,
                title: 'Payment Plan ' + (paymentPlanOptionTabId + 1),
                component: CreatePaymentPlanConfigTabContent,
                closable: true
            }

            setPaymentPlanOptions([...paymentPlanOptions, tabOption]);

            setPaymentPlanOptionKeys([...paymentPlanOptionKeys, paymentPlanOptionTabId]);
            setPaymentPlanOptionTabId(paymentPlanOptionTabId + 1);
            setSelectedPaymentPlanOptionTab("payment-plan-dynamic-" + paymentPlanOptionTabId);
        };

        const removePaymentPlanOptions = (targetKey: any) => {

            // Filter out the tab with the targetKey
            const newTabs = paymentPlanOptions.filter((pane) => pane.key !== targetKey);
            const tabIndex = targetKey.split('-')[3];

            //remove all related state
            unset(paymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_TYPE + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATES_DISPLAY + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATES + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.NUMBER_OF_INSTALMENT + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_NAME + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_VALUE + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.PAYMENT_DAY + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.FROM_MONTH + tabIndex);


            // If the active tab is being removed, switch to the next tab or the previous one
            if (selectedPaymentPlanOptionTab === targetKey && newTabs.length > 0) {
                // If the tab to be removed is active, set the new active key
                setSelectedPaymentPlanOptionTab(newTabs[0].key); // Set the active key to the first remaining tab's key
            }

            setPaymentPlanOptions(newTabs);
        };

        const handlePaymentPlanOptionEditTab = (targetKey: string | React.MouseEvent<Element, MouseEvent>, action: "add" | "remove") => {
            if (action === 'add') {
                addPaymentPlanOption();  // Call the add function when action is 'add'
            } else if (action === 'remove') {
                removePaymentPlanOptions(targetKey);
            }
        };

        /**
        * Function called when the active payment plan option tab (clicking on tab item - title)
        * @param value
        */
        const handlePaymentPlanOptionTabChange = (value: string) => {
            setSelectedPaymentPlanOptionTab(value);
        };

        return (
            <>
                <RouteLeavingGuard
                    when={checkForChanges()}
                    navigate={(path: string) => props.history.push(path)}
                    shouldBlockNavigation={() => {
                        if (checkForChanges()) {
                            return true;
                        }
                        return false;
                    }}
                />
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Spin
                        spinning={loading}
                        tip={"Loading payment plan configuration details"}
                        style={{
                            height: '80%',
                        }}
                    >
                        <Row>
                            <Col
                                xxl={24}
                                xl={24}
                                lg={24}
                                md={24}
                                sm={18}
                                xs={18}
                            >
                                <Tabs
                                    onChange={handlePaymentPlanOptionTabChange}
                                    activeKey={selectedPaymentPlanOptionTab}
                                    type="editable-card"
                                    onEdit={(targetKey, action) => handlePaymentPlanOptionEditTab(targetKey, action)}
                                    destroyInactiveTabPane={false}
                                >
                                    {map(
                                        paymentPlanOptions,
                                        ({
                                            component: TabItemComponent,
                                            paymentPlanConfig,
                                            ...option
                                        }) => {

                                            let keyIndex = option.key.toString().split('-')[3];

                                            return (
                                                <TabPane
                                                    tab={
                                                        option.title
                                                    }
                                                    key={option.key}
                                                    closable={option.closable}
                                                >
                                                    {selectedPaymentPlanOptionTab === option.key &&
                                                        (loading ? (
                                                            getLoadingSkeletons(
                                                                3,
                                                                loading
                                                            )
                                                        ) : (
                                                            <TabItemComponent
                                                                form={props.form}
                                                                setPaymentPlanConfigFormState={setPaymentPlanConfigFormState}
                                                                paymentPlanConfigFormState={paymentPlanConfigFormState}
                                                                setFormHasChanges={props.setFormHasChanges}
                                                                paymentPlanOptionIndex={keyIndex}
                                                                selectedUserCompany={props.selectedUserCompany}
                                                            />
                                                        ))}
                                                </TabPane>
                                            )
                                        }
                                    )}

                                </Tabs>
                            </Col>
                        </Row>
                    </Spin>
                </QueueAnim>
            </>
        );
    }
);

export default CreatePaymentPlanConfigPage;

