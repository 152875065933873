/**
 * Reusable component for populating the Invoice details Invoice tab section.
 */
import {  Col, Row, Select, Form, Input } from 'antd';
import { get, map, isObject, } from 'lodash';
import React from 'react';
import {
    FORM_FIELDS
} from '../../constants/paymentPlanConfigs';
import { Company } from '../../store/companies/types';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    form?: any;
    setPaymentPlanConfigFormState: any;
    paymentPlanConfigFormState: any;
    paymentPlanOptionIndex: number;
    selectedUserCompany: Company;
    tabIndex: number;
}

const CustomFieldsTabContent: React.FC<IProps> = ({
    form,
    setPaymentPlanConfigFormState,
    paymentPlanConfigFormState,
    paymentPlanOptionIndex,
    selectedUserCompany,
    tabIndex
}: IProps) => {

    const { getFieldDecorator} = form;

    /**
       * Function that populates dropdown section.
       * Usually the one on upper left next to refresh button.
       * @param options - list of options
       * @param hasDelete - boolean indicator if the dropdown option has delete functionality
       */
    const populateSelectOptions = () => {
        const optionComponents = map(selectedUserCompany.CustomFieldsConfigure, (option: any) => {
            let label, value;
            if (isObject(option)) {
                label = get(option, 'FieldName');
                value = get(option, 'FieldName');
            } else {
                label = option;
                value = option;
            }

            return (
                <Option key={value} value={value}>
                    {value}
                </Option>
            );
        });

        return optionComponents;
    };

    const generateFields = (fieldName: string, fieldIndex?: number | null) => {
        let field = `${fieldName}${paymentPlanOptionIndex}`;
        if (fieldIndex != null) {
            field = `${field}${fieldIndex}`;
        }

        return field;
    };

    /**
    * Common function for updating the form state object
    * created to minimize repitition when updating form state.
    * @param formStateObject
    */
    const updateFormStateObject = (name: string, val: any) => {
        setPaymentPlanConfigFormState((prev: any) => ({
            ...prev,
            [name]: val
        }));
    };

    const updateFormState: any = (
        name: string
    ) => {
        return (
            e: React.ChangeEvent<HTMLInputElement>
        ) => {
            let value =
                get(e, `target.value`) ||
                get(e, `target.value`);

            if (value == undefined) {
                value = e;
            }

            updateFormStateObject(name, value);
        };
    };

    return (
        <>
            <Row>
                <Col className='mt-7' span={3}>Name: </Col>
                <Col span={5}>
                    <FormItem>
                        {getFieldDecorator(generateFields(FORM_FIELDS.CUSTOM_FIELD_NAME, tabIndex),
                            {
                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.CUSTOM_FIELD_NAME, tabIndex)],
                                rules: [
                                    {
                                        required: true,
                                        message: 'Name required!',
                                    }
                                ]
                            }
                        )(
                            <Select
                                className={`action-bar-select cursor-p`}
                                onSelect={updateFormState(generateFields(FORM_FIELDS.CUSTOM_FIELD_NAME, tabIndex))}

                                placeholder="Please select a custom field"

                            >
                                {populateSelectOptions()}


                            </Select>
                        )}
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col className='mt-7' span={3}>Value: </Col>
                <Col span={5}>
                    <FormItem>
                        {getFieldDecorator(generateFields(FORM_FIELDS.CUSTOM_FIELD_VALUE, tabIndex),
                            {
                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.CUSTOM_FIELD_VALUE, tabIndex)],
                                rules: [
                                    {
                                        required: true,
                                        message: 'Value required!',
                                    }
                                ]
                            }
                        )(
                            <Input
                                onChange={updateFormState(generateFields(FORM_FIELDS.CUSTOM_FIELD_VALUE, tabIndex))}
                            />
                        )}
                    </FormItem>
                </Col>
            </Row>
        </>
    );
};

export default CustomFieldsTabContent;
