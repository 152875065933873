/**
 * Component for populating the Invoice details drawer.
 */

import React, { useEffect, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withDateFormatHandler } from "../common/DateFormatHandler";
import ModalWithSpinner from "../../components/common/ModalWithSpinner";
import { get, groupBy, map } from "lodash";
import { Button, Card, Col, Modal, Row } from "antd";
import {
  convertCamelToSentence,
  downloadFileFromPresignedUrl,
  getTranslatedText,
  openURLNewTab,
} from "../../utils/commonFunctions";
import FontAwesome from "../common/FontAwesome";
import { dateFormatDoMMMYYYYHHmmssSpace, dateFormatMDYYYYhmmssASpaceSlash } from "../../constants/dateFormats";
import { ReportStatus, ReportType } from "../../constants/reportSortAndFilters";
import { withNumberFormatHandler } from "../common/NumberFormatHandler";
import { getPresignedUrlDownloadReportReadyAction } from "../../store/common/actions";
import { DynamicObject } from "../../utils/commonInterfaces";
import { itemDrawerTabItemCardClassName } from "../../constants/common";
import AvatarNameInitialsComponent from "../common/AvatarNameInitialsComponent";
import SheetViewer from "../common/SheetViewer";
import {ScheduledReport } from "../../store/scheduledReports/types";
import { getCurrentUser } from "../../store/users/sagas";
import { IsOrganisationViewAttribute } from "../../constants/authUserAttributes";
import { scheduleMonthlyTimingOptions, scheduleTimingOptions, scheduleTypeOptions } from "../../constants/dashboards";
import moment from "moment-timezone";

interface IProps {
  record: ScheduledReport;
  user: any;
  region?: string;
  readonly formatDateUTCToLocal: (
    date: any,
    fromFormat?: string | null,
    toFormat?: string | null
  ) => string;
  readonly formatDateToUserLocal: (
    date: any,
    fromFormat?: string | null,
    toFormat?: string | null
) => string;
}

export const itemDrawerTabItemContainerMinusHeight = 108;

const ScheduledReportDetailsContent: React.FC<IProps> = ({
  record,
  user,
  region,
  formatDateUTCToLocal,
  formatDateToUserLocal,
}: IProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
  const [downloading, setDownloading] = useState(false);
  const [excelUrl, setExcelUrl] = useState("");
  const RA_SHEET_VIEWER_ID = "app-ra-sheet-viewer";
  useEffect(() => {
    if (
      record &&
      (record.LastReportHistory &&
      record.LastReportHistory.Status === ReportStatus.Complete) &&
      record.ScheduleId !== null
    ) {
      dispatch(
        getPresignedUrlDownloadReportReadyAction(
          {
            EntityKey: get(record, "LastReportId"),
            Region: region
          },
          (res: DynamicObject) => {
            if (res.IsSuccess) {
              setExcelUrl(res.ReportGetURL);
              setDownloading(false);
            } else {
              setDownloading(false);
              Modal.error({
                title: getTranslatedText("Error"),
                content: (
                    <div>{getTranslatedText("Failed to download the report file")}</div>
                ),
                okText: getTranslatedText("OK"),
            });
          }
        }
        )
      );
    }
  }, [record]);

  const downloadReportHandler = () => {
    setDownloading(true);
    dispatch(
      getPresignedUrlDownloadReportReadyAction(
        {
          EntityKey: get(record, "LastReportId"),
          Region: region
        },
        (res: DynamicObject) => {
          if (res.IsSuccess) {
            downloadFileFromPresignedUrl(res.ReportGetURL, () => {
              setDownloading(false);
            });
          } else {
            setDownloading(false);
            Modal.error({
              title: "Error",
              content: (
                <div>
                  <div>Failed to download the report file!</div>
                </div>
              ),
            });
          }
        }
      )
    );
  };

  const handleDownload = () => {
    openURLNewTab(excelUrl);
  };

  const Company = get(record, 'Company');

  const getSchedule = (ScheduleType: number, ScheduleTiming1: number, ScheduleTiming2?: number) => {
    const getType = scheduleTypeOptions.find(option => option.value === ScheduleType)|| '';
    const getTiming1 = scheduleTimingOptions(ScheduleType).find(option => option.value === ScheduleTiming1)|| '';
    const getTiming2 = ScheduleType === 3 ?  scheduleMonthlyTimingOptions().find(option => option.value === ScheduleTiming2) : '';

    const type = getType ? getType.label: '';
    const timing1 = getTiming1 ? getTiming1.label: '';
    const timing2 = getTiming2 ? ' on the ' + getTiming2.label : '';

    return type.concat(", ", timing1, timing2);
  };

  const ReportTitle = (() => {
        const Description = get(record, 'Description');
        const Type = get(record, 'Type');
        const  parts: string[] = [Description];
        let typeDisplay: string | undefined;
        switch (Type) {
            case ReportType.Page: typeDisplay = 'Page'; break;
            case ReportType.Dashboard: typeDisplay = 'Dashboard'; break;
        }
        if (typeDisplay) {
            parts.push(typeDisplay);
        }
        parts.push('Report');
        return parts.join(' ');
    })();

    const formatIfDate = (value: string, title: string) => {
      const date = moment(value, dateFormatMDYYYYhmmssASpaceSlash, true);;
     
      if (date.isValid()) {
        // Use local time on these reports as they use local times on date filtering
        if (title == "SalesWidget" || title == "InvoicingAndSettlementsWidget" || title == "DailySalesOutWidget" || title == "CashflowForecastingWidget") 
        {
          return formatDateToUserLocal(
            value,
            dateFormatMDYYYYhmmssASpaceSlash,
            dateFormatDoMMMYYYYHHmmssSpace
          );
        }
        else {
          return formatDateUTCToLocal(
            value,
            dateFormatMDYYYYhmmssASpaceSlash,
            dateFormatDoMMMYYYYHHmmssSpace
          );
        }
      }

      return value; 
    };

  return (
    <>
      <div
        className="details-item-container"
        style={{
          height: window.innerHeight - itemDrawerTabItemContainerMinusHeight,
        }}
      >
        <Row style={{ height: "100%" }} gutter={10}>
        <Col style={{ height: "5%"}} md={24}></Col>
          <Col style={{ height: "100%" }} md={16}>
            <Card
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              bodyStyle={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                height: 0,
              }}
              className={itemDrawerTabItemCardClassName}
              title="Schedule Report Excel"
            >
              {excelUrl && (
                <div style={{ textAlign: "right" }}>
                  <Button
                    type="link"
                    onClick={handleDownload}
                    style={{ padding: 0 }}
                  >
                    <FontAwesome icon={["fa", "file-download"]} />
                    &nbsp;
                    <span>Download</span>
                  </Button>
                </div>
              )}
              {(record.LastReportHistory && record.LastReportHistory.Status) === ReportStatus.Complete && <SheetViewer
                id={RA_SHEET_VIEWER_ID}
                url={excelUrl}
                style={{
                  height: 700,
                  width: "100%",
                }}
                isCsv={false}
              />}
            </Card>
          </Col>

          <Col md={8}>
            <Card title="Schedule details">
              <Row>
                {isOrgView && Company && <Col>Company: {get(Company, 'Name')}</Col>}
                <Col>Type: {ReportTitle}</Col>
                <Col>
                  Next Report:{" "}
                  {formatDateUTCToLocal(
                    get(record, "NextReportDateTime"),
                    undefined,
                    dateFormatDoMMMYYYYHHmmssSpace
                  )}
                </Col>
                <Col>
                  Previous Report:{" "}
                  {get(record, "LastReportDateTime") ? formatDateUTCToLocal(
                    get(record, "LastReportDateTime"),
                    undefined,
                    dateFormatDoMMMYYYYHHmmssSpace
                  )  : '--'}
                </Col>
                <Col>Schedule: {getSchedule(record.ScheduleType, record.ScheduleTiming1, record.ScheduleTiming2)}</Col>
                <Col>Applying Date Filter: {record.ApplyScheduleDate ? 'Yes' : 'No'}</Col>
              </Row>
            </Card>
            <div className="spacer-15" />      
            {record.LastReportHistory ?     
              <Card title="Filters applied">
                <Row>
                {map(groupBy(record.LastReportHistory.ReportAppliedFilters, e => e.Name), (records, name) => (
                  !name.includes("ResourceScopes") && (
                    <Col key={name}>
                      {convertCamelToSentence(name)}:{' '}
                      {
                        records.length === 1
                          ? <>{formatIfDate(records[0].Value, record.Title)}</>
                          : <ul>
                            {map(records, (rec, i) => <li key={i}>{formatIfDate(rec.Value, record.Title)}</li>)}
                          </ul>
                      }
                    </Col>
                  )))}
                </Row>
              </Card>
              : 
              <Card title="Filters applied">
                <Row>
                {map(groupBy(record.ReportScheduleFilters, e => e.Name), (records, name) => (
                  !name.includes("ResourceScopes") && (
                    <Col key={name}>
                      {convertCamelToSentence(name)}:{' '}
                      {
                          records.length === 1
                            ? <>{formatIfDate(records[0].Value, record.Title)}</>
                            : <ul>
                              {map(records, (rec, i) => <li key={i}>{formatIfDate(rec.Value, record.Title)}</li>)}
                            </ul>
                      }
                    </Col>
                  )))}
                </Row>
              </Card>
            }
            <div className="spacer-15" />
            <Card title="Report details">
              <Row>
                {isOrgView && Company && <Col>Company: {get(Company, 'Name')}</Col>}
                <Col>Type: {ReportTitle}</Col>
                <Col>
                  Start Time:{" "}
                  {get(record, "LastReportHistory.CreatedDateTime") ? formatDateUTCToLocal(
                    get(record, "LastReportHistory.CreatedDateTime"),
                    undefined,
                    dateFormatDoMMMYYYYHHmmssSpace
                  ) : '--'}
                </Col>
                <Col>
                  End Time:{" "}
                  {get(record, "LastReportHistory.CompletedDateTime") ?
                    get(record, "LastReportHistory.CompletedDateTime") && formatDateUTCToLocal(
                    get(record, "LastReportHistory.CompletedDateTime"),
                    undefined,
                    dateFormatDoMMMYYYYHHmmssSpace
                  ) : "--"}
                </Col>
                <Col>
                  State:{" "}
                  {get(record, "LastReportHistory.Status")}
                </Col>
                <Col>
                    <Button
                      style={{ paddingLeft: "0px" }}
                      type="link"
                      onClick={downloadReportHandler}
                      title="Download to Excel"
                    >
                      <FontAwesome
                        icon={["fas", "cloud-download-alt"]}
                        size="lg"
                      />
                      <span style={{ padding: "5px" }}>
                        {" "}
                        Download to Excel
                      </span>
                    </Button>
                </Col>
              </Row>
            </Card>
            <div className="spacer-15" />
            {user && (
              <Card>
                <Row>
                  <Col className="ta-right">
                    <div
                      className="mb-10"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <AvatarNameInitialsComponent
                        fullName={user.GivenName}
                        size={80}
                        fontSize={24}
                        showTooltip
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div className="mr-10">
                        <b>Created By:</b>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Col>{user.GivenName} </Col>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            )}
            <div className="spacer-15" />
          </Col>
        </Row>
      </div>
      {downloading && (
        <Suspense fallback={null}>
          <ModalWithSpinner
            modalTitle="Downloading report"
            modalVisible={downloading}
            displayMessage="Please wait while we get the file from storage . . ."
          />
        </Suspense>
      )}
    </>
  );
};

export default withDateFormatHandler(
  withNumberFormatHandler(ScheduledReportDetailsContent)
);
