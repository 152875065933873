import { creditsSortByOptions, rebatesSortByOptions } from '../../constants/creditsSortAndFilters';
import {
    AddCommentCommonFilterPayload,
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import {
    PageData,
    CustomField,
    Attachment,
    ConversationState,
} from '../common/types';
import { Company } from '../companies/types';
import { Customer, AgedTrialBalance } from '../customers/types';

export enum CreditsActionTypes {
    GET_CREDITS_REQUEST = '@@credits/GET_CREDITS_REQUEST',
    GET_CREDITS_FOR_ORGANISATION_REQUEST = '@@credits/GET_CREDITS_FOR_ORGANISATION_REQUEST',
    GET_CREDITS_SUCCESS = '@@credits/GET_CREDITS_SUCCESS',
    GET_CREDITS_ERROR = '@@credits/GET_CREDITS_ERROR',

    GET_REBATES_REQUEST = '@@credits/GET_REBATES_REQUEST',
    GET_REBATES_SUCCESS = '@@credits/GET_REBATES_SUCCESS',
    GET_REBATES_ERROR = '@@credits/GET_REBATES_ERROR',

    UPDATE_CREDITS_FILTERS = '@@credits/UPDATE_CREDITS_FILTERS',
    UPDATE_CREDITS_SORT_BY_AND_STATE = '@@credits/UPDATE_CREDITS_SORT_BY_AND_STATE',
    UPDATE_CREDITS_TABLE_FILTER_STATE = '@@credits/UPDATE_CREDITS_TABLE_FILTER_STATE',
    CLEAR_CREDITS_STATE_ALL_TABLE_FILTERS = '@@credits/CLEAR_CREDITS_STATE_ALL_TABLE_FILTERS',
    CLEAR_CREDITS_STATE_DATA = '@@credits/CLEAR_CREDITS_STATE_DATA',
    CLEAR_CREDITS_LIST_DATA = '@@invoices/CLEAR_CREDITS_LIST_DATA',

    GET_CREDIT_CUSTOM_FIELDS_FOR_COMPANY_REQUEST = '@@credits/GET_CREDIT_CUSTOM_FIELDS_FOR_COMPANY_REQUEST',
    
    SET_CREDIT_SELECTED_ID_REQUEST = '@@credits/SET_CREDIT_SELECTED_ID_REQUEST',
    SET_CREDIT_SELECTED_ID_SUCCESS = '@@credits/SET_CREDIT_SELECTED_ID_SUCCESS',
    GET_CREDIT_DATA_REQUEST = '@@credits/GET_CREDIT_DATA_REQUEST',
    GET_ORGANISATION_CREDIT_DATA_REQUEST = '@@credits/GET_ORGANISATION_CREDIT_DATA_REQUEST',
    GET_CREDIT_DATA_SUCCESS = '@@credits/GET_CREDIT_DATA_SUCCESS',
    GET_CREDIT_DATA_ERROR = '@@credits/GET_CREDIT_DATA_ERROR',
    CLEAR_CREDIT_DATA_SUCCESS = '@@credits/CLEAR_CREDIT_DATA_SUCCESS', 

    GET_CREDITS_BY_CREDIT_CODES_REQUEST = '@@credits/GET_CREDITS_BY_CREDIT_CODES_REQUEST',
    GET_CREDITS_BY_CREDIT_CODES_SUCCESS = '@@credits/GET_CREDITS_BY_CREDIT_CODES_SUCCESS',
    GET_CREDITS_BY_CREDIT_CODES_ERROR = '@@credits/GET_CREDITS_BY_CREDIT_CODES_ERROR',

    GET_CREDIT_INVOICES_REQUEST = '@@credits/GET_CREDIT_INVOICES_REQUEST',
    GET_CREDIT_INVOICES_SUCCESS = '@@credits/GET_CREDIT_INVOICES_SUCCESS',
    GET_CREDIT_INVOICES_ERROR = '@@credits/GET_CREDIT_INVOICES_ERROR',
    UPDATE_CREDIT_INVOICES_FILTERS = '@@credits/UPDATE_CREDIT_INVOICES_FILTERS',
    UPDATE_CREDIT_INVOICES_SORT_BY_AND_STATE = '@@credits/UPDATE_CREDIT_INVOICES_SORT_BY_AND_STATE',
    UPDATE_CREDIT_INVOICES_TABLE_FILTER_STATE = '@@credits/UPDATE_CREDIT_INVOICES_TABLE_FILTER_STATE',

    GET_CREDIT_CONVERSATION_REQUEST = '@@credits/GET_CREDIT_CONVERSATION_REQUEST',
    GET_CREDIT_CONVERSATION_SUCCESS = '@@credits/GET_CREDIT_CONVERSATION_SUCCESS',
    GET_CREDIT_CONVERSATION_ERROR = '@@credits/GET_CREDIT_CONVERSATION_ERROR',
    UPDATE_CREDIT_CONVERSATION_FILTERS = '@@credits/UPDATE_CREDIT_CONVERSATION_FILTERS',
    UPDATE_CREDIT_CONVERSATION_TABLE_FILTER = '@@credits/UPDATE_CREDIT_CONVERSATION_TABLE_FILTER',
    UPDATE_CREDIT_CONVERSATION_SORT_BY = '@@credits/UPDATE_CREDIT_CONVERSATION_SORT_BY',

    GET_CREDIT_CREDIT_CHANGES_REQUEST = '@@credits/GET_CREDIT_CREDIT_CHANGES_REQUEST',
    GET_ORGANISATION_CREDIT_CREDIT_CHANGES_REQUEST = '@@credits/GET_ORGANISATION_CREDIT_CREDIT_CHANGES_REQUEST',
    GET_CREDIT_CREDIT_CHANGES_SUCCESS = '@@credits/GET_CREDIT_CREDIT_CHANGES_SUCCESS',
    GET_CREDIT_CREDIT_CHANGES_ERROR = '@@credits/GET_CREDIT_CREDIT_CHANGES_ERROR',
    UPDATE_CREDIT_CREDIT_CHANGES_FILTERS = '@@credits/UPDATE_CREDIT_CREDIT_CHANGES_FILTERS',
    UPDATE_CREDIT_CREDIT_CHANGES_TABLE_FILTER = '@@credits/UPDATE_CREDIT_CREDIT_CHANGES_TABLE_FILTER',
    UPDATE_CREDIT_CREDIT_CHANGES_SORT_BY = '@@credits/UPDATE_CREDIT_CREDIT_CHANGES_SORT_BY',

    CREDIT_ADD_COMMENT_REQUEST = '@@credits/CREDIT_ADD_COMMENT_REQUEST',

    GET_CREDITS_RECONCILIATION_REPORT_REQUEST = '@@credits/GET_CREDITS_RECONCILIATION_REPORT_REQUEST',

    CREATE_CREDIT_MANUALLY_REQUEST = '@@credits/CREATE_CREDIT_MANUALLY_REQUEST',
    EDIT_CREDIT_MANUALLY_REQUEST = '@@credits/EDIT_CREDIT_MANUALLY_REQUEST',
    DELETE_CREDIT_MANUALLY_REQUEST = '@@credits/DELETE_CREDIT_MANUALLY_REQUEST',
    CREDITS_FETCHED_FOR_ORGANISATION_RECEIVED = '@@credits/CREDITS_FETCHED_FOR_ORGANISATION_RECEIVED'
}

export interface CreditsState {
    readonly loading: boolean;
    readonly saveCreditDataLoading: boolean;
    readonly errorMessages: string[];
    readonly data: Credit[];
    readonly pageData: PageData;
    readonly filters: GetCreditsRequestPayload['filters'];
    readonly sortBy: GetCreditsRequestPayload['sortBy'];
    readonly sortAscending: boolean;
    readonly creditState: string;
    readonly tableFilter: string | undefined;
    readonly activeData: {
        loading: boolean;
        record: {};
        conversation: ConversationState;
        creditChanges: CreditChangesState;
        selectedId: string | null;
        errorMessages: string[];
        rebates: {
            readonly loading: boolean;
            readonly saveCreditDataLoading: boolean;
            readonly errorMessages: string[];
            readonly data: Credit[];
            readonly pageData: PageData;
            readonly filters: GetCreditsRequestPayload['filters'];
            readonly sortBy: GetCreditsRequestPayload['sortBy'];
            readonly sortAscending: boolean;
            readonly creditState: string;
            readonly tableFilter: string | undefined;
        };
    };
}

export interface Credit {
    readonly Id: string;
    readonly CreditCode: string;
    readonly Type: string;
    readonly Number: string;
    readonly CreatedDate: string;
    readonly CreatedType: string;
    readonly LocalCreatedDate: string;
    readonly AvailableDate: string;
    readonly LocalAvailableDate: string;
    readonly OriginalAmount: number;
    readonly AmountAvailable: number;
    readonly State: string; // Open | Closed
    readonly ClosedDate: string;
    readonly Customer: Customer;
    readonly AgedTrialBalance: AgedTrialBalance;
    readonly Uri: string;
    readonly CustomFields: CustomField[];
    readonly Attachments: Attachment[];
    readonly Description: string;
    readonly Percentage: number;
    readonly IsRecurring: boolean;
    readonly Company: Company;
}

export interface CreditVM extends Credit {
    UsePercentage: boolean;
}

export interface GetCreditsRequestPayload {
    filters: {
        CompanyIds?: string[];
    };
    tableFilter?: string;
    creditState: string;
    sortBy: typeof creditsSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    isUsingCloudImportType?: boolean;
    excludeCredits?: boolean;
    region?: string;
    conversionCurrency?: number;
    organisationCurrencies?: CreditOrganisationCurrency[]
}

export interface GetRebatesRequestPayload {
    filters: {};
    tableFilter?: string;
    creditState: string;
    sortBy: typeof rebatesSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    isUsingCloudImportType?: boolean;
    excludeCredits?: boolean;
}

export interface BulkSaveCreditUpdateRequestPayload {
    filter: {};
    creditIds: string[] | number[];
    excludeCredits: boolean;
    creditObject: {};
    callback?: (response: ResponseModalObject) => void;
}

export interface DeleteCreditManuallyRequestPayload {
    filter: {};
    creditIds: string[] | number[];
    excludeCredits: boolean;
    callback?: (response: ResponseModalObject) => void;
}

export interface SaveCreditUpdateRequestPayload {
    creditId: string;
    creditObject: {};
    callback?: (response: ResponseModalObject) => void;
}

export interface GetCreditChangesRequestPayload {
    filters: {};
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    creditChangesTableFilter: string;
}

export interface CreditChangesState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: Credit[];
    readonly pageData: PageData;
    readonly filters: GetCreditChangesRequestPayload['filters'];
    readonly creditChangesTableFilter: string;
    readonly sortBy: GetCreditChangesRequestPayload['sortBy'];
    readonly sortAscending: boolean;
}

export interface CreditChanges {}

export interface CreditAddCommentRequestPayload
    extends AddCommentCommonFilterPayload {
    creditIds: string[] | number[];
    excludeCredits: boolean;
}

export interface RequestCreateCreditManuallyPayload {
    Credits: Partial<CreditVM>[];
    callback?: (response: any) => void;
}

export interface RequestEditCreditManuallyPayload {
    Credit: Partial<CreditVM>;
    callback?: (response: any) => void;
}

export interface CreditOrganisationCurrency {
    Value: string
    Rate: number
}

export interface CreditsFetchedData {
    OnCreditsFetchedForOrganisation: {
        Credits: Credit[];
    };
};

export interface CreditsFetchedForOrganisationPayload {
    data: CreditsFetchedData;
    pageSize: number;
    currentPage: number;
}