/**
 * Component for populating the TaskActionManuallyAllocatePaymentPanel panel when clicking on `Action` button for Manual Allocate Payment task items.
 */

import {
    Button,
    Col,
    Form,
    Row,
    Select,
    Modal,
    Drawer,
    Spin
} from 'antd';
import { get, map, isEmpty } from 'lodash';
import React, { useEffect, useState,lazy,Suspense} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withDateFormatHandler } from '../../common/DateFormatHandler';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { ApplicationState } from '../../../store';
import { Task } from '../../../store/tasks/types';
import { confirmRemittanceAdviceDisregardTasksRequest, organisationConfirmRemittanceAdviceDisregardTasksRequest } from '../../../store/remittanceAdvices/actions';
import { CompanyUserRole } from '../../../store/companies/types';
import { getViewingUser } from '../../../store/tasks/sagas';
import AvatarNameInitialsComponent from '../../common/AvatarNameInitialsComponent';
import { IsOrganisationViewAttribute, SubAttribute } from '../../../constants/authUserAttributes';
import { getCurrentUser } from '../../../store/users/sagas';
import { populatePopoverContainer } from '../../../utils/commonFunctions';
import ModalWithSpinner from '../../common/ModalWithSpinner';
import { fileToObject } from 'antd/lib/upload/utils';
import { confirmModalCancelText, confirmModalOkText } from '../../../config/config';

const { confirm } = Modal;

const TaskActionRemittanceAdvicePanelContent = lazy(
    () => import('./TaskActionRemittanceAdvicePanelContent')); 

interface IProps {
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly selectedTaskId: string;
    readonly getSelectedTasksValues: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
        sortBy: string,
        sortAscending: boolean,
        recordLimit?: number,
    };
    readonly containerRef?: any;
}
const { Item: FormItem } = Form;
const { Option } = Select;

const TaskActionRemittanceAdvicePanelOptionContent: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    selectedTaskId,
    getSelectedTasksValues,
    containerRef
}: IProps) => {

    const { keysToUse, allExcept, filterObject, sortBy, sortAscending, recordLimit } = getSelectedTasksValues();

    const dispatch = useDispatch();
    const { getFieldDecorator, validateFields, resetFields } = form;
    const [showConfirmPanel, setShowConfirmPanel] = useState<boolean>(false); 

    const [submitDisregardLoading, setSubmitDisregardLoading] = useState<boolean>(false);

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const currentUser = useSelector(getCurrentUser);
    const currentUserId: string = get(currentUser, SubAttribute);
    const { loading: viewingUserLoading, user: viewingUser } = useSelector(getViewingUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';

    const viewerGivenName = get(viewingUser, 'GivenName');
    const viewerFamilyName = get(viewingUser, 'FamilyName'); 
    /**
     * Function for closing the panel.
     * When `Cancel` button is clicked.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Listener function if the drawer visibility changes.
     * If drawer closes, resets the field values.
     * If drawer opens, gets action options.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when clicking the `Next` button
     */
    const handleNextButton = () => {
        validateFields((err: any, values: DynamicObject) => {
            if (!err) {
                // I honestly dont know what to do but this should either trigger Disregard orrrr Confirm RA 
                const actionValue = get(values, 'Option'); 
                if(actionValue == 0){
                    handleDisregardTasksForm();
                }
                else {
                    setShowConfirmPanel(true);
                }
            }
        });
    };

    /**
     * Function called when clicking the `Action item` button
     * Disregard Remittance Advice 
     */
    const handleDisregardTasksForm = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: 'Warning',
            content: (
                <div>
                    Disregarding the task will close the remittance advice.
                    <div className="mt-8">
                        Would you like to continue?
                    </div>
                </div>
            ),
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
            onOk() {
                disregardRemittanceAdvice();
            }
        });
    };

    const disregardRemittanceAdvice = () => {
        setSubmitDisregardLoading(true);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        const payload = {
            filter: filterObject,
            taskIds: keysToUse,
            excludeTasks: allExcept,
            sortBy, 
            sortAscending, 
            recordLimit,
            CompanyId: selectedCompanyId
        };

        const taskAction = isOrgView ? organisationConfirmRemittanceAdviceDisregardTasksRequest : confirmRemittanceAdviceDisregardTasksRequest;

        dispatch(
            taskAction({
                ...payload,
                callback: disregardRemittanceAdviceModal,
            })
        )
    };

    /**
     * Function responsible for showing the response modal after disregarding Remittance Advice.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const disregardRemittanceAdviceModal = ({
        IsSuccess,
        Messages,
        RefetchList = true,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
        RefetchList: boolean;
    }) => {
        setSubmitDisregardLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Remittance advice has been disregarded successfully!',
                onOk: () => {
                    if (closePanel) closePanel(RefetchList);
                },
            });
        } else {
            let errorMessageContent: any = `Failed to disregard remittance advice!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }
            Modal.error({
                title: 'Error',
                content: errorMessageContent,
            });
        }   
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const confirmRemittanceAdvicePanel = () => {
        return (
            <Suspense fallback={<Spin />}>
                <Drawer
                    visible={showConfirmPanel}
                    closable={false}
                    getContainer={populatePopoverContainer(containerRef)}
                    title={<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>{"Confirm remittance advice"}</div>
                        {!viewingUserLoading && viewingUser && get(viewingUser, 'UserId') !== currentUserId
                            && <div>
                                <AvatarNameInitialsComponent
                                    fullName={`${viewerGivenName} ${viewerFamilyName}`}
                                    size={40}
                                    fontSize={16}
                                    showTooltip={true}
                                />
                            </div>
                        }
                    </div>}
                    width={1400}
                    className="protected-drawer"
                >
                <TaskActionRemittanceAdvicePanelContent
                    visible={showConfirmPanel} 
                    selectedTaskId={selectedTaskId}
                    closePanel={closePanel}
                    getSelectedTasksValues={getSelectedTasksValues}
                />
                    
                </Drawer>
            </Suspense>
        );
    }

    /**
     * Function to populate the loading content.
     * Shows a loading skeleton if the content is being populated (API not finished fetching data for action options).
     */
    const populatePanelContent = () => {       
        const defaultRAvalue = keysToUse.length == 1 ? 1: 0;
            return (
                <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                    <Row>
                        <Col span={24}>
                            <Row className="mb-16" type="flex" align="middle">
                                <Col span={24}>
                                    Choose which action to complete then click Next.
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-10" type="flex" >
                                <Col span={24}>
                                <FormItem
                                        {...formItemLayout}
                                        label="Action"
                                    >
                                        {getFieldDecorator("Option", {
                                            initialValue: defaultRAvalue
                                        }
                                        )(
                                            <Select style={{ width: '100%' }}>
                                              {
                                                (keysToUse.length == 1) && 
                                                    <Option value={1}> Confirm remittance advice </Option> 
                                              }
                                              <Option value={0}> Disregard </Option>  
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {confirmRemittanceAdvicePanel()}
                </Form>
                
            );
    };

    return (
        <Row>
            <Col className="dropdown-multiline-cont">
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleNextButton}
                        >
                            Next
                        </Button>
                        <Button className="buttonGrey" onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
            {submitDisregardLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle="Disregarding Remittance Advice(s)"
                        modalVisible={submitDisregardLoading}
                        displayMessage="Please wait while disregarding remittance advice(s) . . ."
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const TaskResolveCustomerAssistanceTicketPanelContentForm = Form.create({
    name: 'task-resolve-ticket-panel-form',
})(TaskActionRemittanceAdvicePanelOptionContent);

export default withRouter(
    withDateFormatHandler(TaskResolveCustomerAssistanceTicketPanelContentForm)
);
