import { ticketsSortByOptions } from '../../constants/customerAssistanceSortAndFilters';
import {
    Attachment,
    ConversationLine,
    ConversationState,
    GetConversationRequestPayload,
    PageData,
} from '../common/types';
import { Customer } from '../customers/types';
import { Invoice } from '../invoices/types';
import { Task } from '../tasks/types';
import { commonInvoiceListInvoicesSortOptions } from '../../constants/invoicesSortAndFilters';
import { AddCommentCommonFilterPayload, AssignUserCommonFilterPayload } from '../../utils/commonInterfaces';

export enum TicketsActionTypes {
    GET_TICKETS_REQUEST = '@@tickets/GET_TICKETS_REQUEST',
    GET_TICKETS_SUCCESS = '@@tickets/GET_TICKETS_SUCCESS',
    GET_TICKETS_ERROR = '@@tickets/GET_TICKETS_ERROR',
    UPDATE_TICKETS_FILTERS = '@@tickets/UPDATE_TICKETS_FILTERS',
    UPDATE_TICKETS_SORT_BY_AND_STATUS = '@@tickets/UPDATE_TICKETS_SORT_BY_AND_STATUS',
    UPDATE_TICKETS_TABLE_FILTER_STATUS = '@@tickets/UPDATE_TICKETS_TABLE_FILTER_STATUS',
    CLEAR_TICKETS_STATE_ALL_TABLE_FILTERS = '@@tickets/CLEAR_TICKETS_STATE_ALL_TABLE_FILTERS',
    CLEAR_TICKETS_STATE_DATA = '@@tickets/CLEAR_TICKETS_STATE_DATA',
    CHECK_TICKETS_READY_STATE = '@@tickets/CHECK_TICKETS_READY_STATE',
    GET_TICKET_CUSTOM_FIELDS_FOR_COMPANY_REQUEST = '@@tickets/GET_TICKET_CUSTOM_FIELDS_FOR_COMPANY_REQUEST',
    SET_TICKET_SELECTED_ID_REQUEST = '@@tickets/SET_TICKET_SELECTED_ID_REQUEST',
    SET_TICKET_SELECTED_ID_SUCCESS = '@@tickets/SET_TICKET_SELECTED_ID_SUCCESS',
    GET_TICKET_DATA_REQUEST = '@@tickets/GET_TICKET_DATA_REQUEST',
    GET_TICKET_DATA_SUCCESS = '@@tickets/GET_TICKET_DATA_SUCCESS',
    GET_TICKET_DATA_ERROR = '@@tickets/GET_TICKET_DATA_ERROR',
    CLEAR_TICKET_DATA_SUCCESS = '@@tickets/CLEAR_TICKET_DATA_SUCCESS',
    GET_TICKET_INFO_FOR_CHAT_VALIDATION_REQUEST = '@@tickets/GET_TICKET_INFO_FOR_CHAT_VALIDATION_REQUEST',

    // Invoices
    GET_TICKET_DATA_INVOICES_REQUEST = '@@tickets/GET_TICKET_DATA_INVOICES_REQUEST',
    GET_TICKET_DATA_INVOICES_SUCCESS = '@@tickets/GET_TICKET_DATA_INVOICES_SUCCESS',
    GET_TICKET_DATA_INVOICES_ERROR = '@@tickets/GET_TICKET_DATA_INVOICES_ERROR',
    UPDATE_TICKET_DATA_INVOICES_FILTERS = '@@tickets/UPDATE_TICKET_DATA_INVOICES_FILTERS',
    UPDATE_TICKET_DATA_INVOICES_SORT_BY_AND_STATE = '@@tickets/UPDATE_TICKET_DATA_INVOICES_SORT_BY_AND_STATE',

    // Tasks
    GET_TICKET_DATA_TASKS_REQUEST = '@@tickets/GET_TICKET_DATA_TASKS_REQUEST',
    GET_TICKET_DATA_TASKS_SUCCESS = '@@tickets/GET_TICKET_DATA_TASKS_SUCCESS',
    GET_TICKET_DATA_TASKS_ERROR = '@@tickets/GET_TICKET_DATA_TASKS_ERROR',
    UPDATE_TICKET_DATA_TASKS_FILTERS = '@@tickets/UPDATE_TICKET_DATA_TASKS_FILTERS',
    UPDATE_TICKET_DATA_TASKS_SORT_BY = '@@tickets/UPDATE_TICKET_DATA_TASKS_SORT_BY',

    // Resolve
    GET_RESOLVE_TICKET_OPTIONS_REQUEST = '@@tickets/GET_RESOLVE_TICKET_OPTIONS_REQUEST',
    RESOLVE_QUERY_REQUEST = '@@tickets/RESOLVE_QUERY_REQUEST',
    ORGANISATION_RESOLVE_QUERY_REQUEST = '@@tickets/ORGANISATION_RESOLVE_QUERY_REQUEST',

    // Conversation
    GET_TICKET_CONVERSATION_REQUEST = '@@tickets/GET_TICKET_CONVERSATION_REQUEST',
    GET_TICKET_CONVERSATION_SUCCESS = '@@tickets/GET_TICKET_CONVERSATION_SUCCESS',
    GET_TICKET_CONVERSATION_ERROR = '@@tickets/GET_TICKET_CONVERSATION_ERROR',
    UPDATE_TICKET_CONVERSATION_FILTERS = '@@tickets/UPDATE_TICKET_CONVERSATION_FILTERS',
    UPDATE_TICKET_CONVERSATION_TABLE_FILTER = '@@tickets/UPDATE_TICKET_CONVERSATION_TABLE_FILTER',
    UPDATE_TICKET_CONVERSATION_SORT_BY = '@@tickets/UPDATE_TICKET_CONVERSATION_SORT_BY',
    TICKET_ADD_COMMENT_REQUEST = '@@tickets/TICKET_ADD_COMMENT_REQUEST',
    TICKET_RESOURCE_REPLY_REQUEST = '@@tickets/TICKET_RESOURCE_REPLY_REQUEST',
    GET_TICKET_DISCUSSION_REQUEST = '@@tickets/GET_TICKET_DISCUSSION_REQUEST',
    GET_TICKET_DISCUSSION_SUCCESS = '@@tickets/GET_TICKET_DISCUSSION_SUCCESS',
    GET_TICKET_DISCUSSION_ERROR = '@@tickets/GET_TICKET_DISCUSSION_ERROR',
    CLEAR_TICKET_DISCUSSION = '@@tickets/CLEAR_TICKET_DISCUSSION',

    TICKET_ASSIGN_USER_REQUEST = '@@tickets/TICKET_ASSIGN_USER_REQUEST',
}

export interface TicketsState {
    readonly loading: boolean;
    readonly saveTicketDataLoading: boolean;
    readonly errorMessages: string[];
    readonly data: Ticket[];
    readonly pageData: PageData;
    readonly filters: GetTicketsRequestPayload['filters'];
    readonly sortBy: GetTicketsRequestPayload['sortBy'];
    readonly sortAscending: boolean;
    readonly ticketStatus: number;
    readonly ticketTableFilter: string | undefined;
    readonly activeData: {
        loading: boolean;
        record: {};
        selectedId: string | null;
        errorMessages: string[];
        invoices: {
            loading: boolean;
            errorMessages: string[];
            data: Invoice[];
            pageData: PageData;
            filters: {};
            sortBy: string;
            sortAscending: boolean;
            invoiceState: string;
        };
        tasks: {
            loading: boolean;
            errorMessages: string[];
            data: Task[];
            pageData: PageData;
            filters: {};
            sortBy: string;
            sortAscending: boolean;
        };
        conversation: ConversationState;
        discussion: TicketDiscussionState;
    };
}

export interface GetTicketsRequestPayload {
    filters: {
        DisplayName: string;
        CustomerCode: string;
        CreatedDateMin: string;
        CreatedDateMax: string;
        ClosedDateMin: string;
        ClosedDateMax: string;
        LastChatDateMin: string;
        LastChatDateMax: string;
        Status: number;
        TicketOptionReasons: string[];
    };
    sortBy: typeof ticketsSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    ticketTableFilter?: string;
    isUsingCloudImportType?: boolean;
}

export interface GetTicketDiscussionRequestPayload {
    record: Ticket;
    currentPage: number;
}

export interface GetTicketInfoForChatValidationPayload {
    TicketId: string;
    CompanyId: string;
    callback: (response: any) => void
}

export interface TicketOption {
    readonly Id: string;
    readonly Reason: string;
    readonly CaptureMessage: string;
    readonly Type: string;
}

export interface Ticket {
    readonly Id: string;
    readonly TicketNumber: string;
    readonly State: string;
    readonly Details: string;
    readonly ResolvedReason: string;
    readonly ResolvedDateTime: string;
    readonly CreatedDateTime: string;
    readonly Customer: Customer;
    readonly TicketOption: TicketOption;
    readonly Uri: string;
    readonly Attachments: Attachment[];
    readonly ConversationChatLine: ConversationLine;
    readonly ConversationLine: ConversationLine;
}

export interface GetTicketDataInvoicesRequestPayload {
    filters: {};
    sortBy: typeof commonInvoiceListInvoicesSortOptions[number]['value'];
    sortAscending: boolean;
    invoiceState: string;
    pageSize: number;
    currentPage: number;
}

export interface GetTicketDataTasksRequestPayload {
    filters: {};
    //sortBy: typeof commonInvoiceListInvoicesSortOptions[number]['value'];
    //sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

export interface WorkflowTransition {
    Trigger: string;
    DataCapture: DataCapture | null;
}

export interface DataCapture {
    CaptureDateTime: boolean;
    DateTimeLabel: string;
    CaptureComment: boolean;
    CommentLabel: string;
}

export interface TicketAddCommentRequestPayload
    extends AddCommentCommonFilterPayload {
    ticketIds: string[] | number[];
    excludeTickets: boolean;
}

export interface TicketResourceReplyRequestPayload {
    CommunicationMethod: string;
    ResourceId: string;
    CustomerId: string;
    CommunicationContent: string;
    AttachmentId?: string;
    callback?: (response: any) => void;
}

export interface TicketDiscussionState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: ConversationLine[];
    readonly pageData: PageData;
}

export interface TicketAssignUserRequestPayload
    extends AssignUserCommonFilterPayload {
    ticketIds: string[] | number[];
    excludeTickets: boolean;
    userObject: {};
    companyId: string;
}