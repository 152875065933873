import API, { graphqlOperation } from '@aws-amplify/api';
import { filter, get, isEmpty, lowerCase, toLower } from 'lodash';
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { USERS_PAGE } from '../../config/tableAndPageConstants';
import queries from '../../graphql/queries.graphql';
import { checkShouldRequestRefetch } from '../../utils/commonFunctions';
import { DynamicObject } from './../../utils/commonInterfaces';
import {
    changeUsersRoleResponseAction,
    getUserByEmailAction,
    getUserByNameAction,
    getUserPreferencesRequestAction,
    getUserPreferencesSuccessAction,
    getUsersErrorAction,
    getUsersRequestAction,
    getUsersSuccessAction,
} from './actions';
import { UsersActionTypes } from './types';

export const getCurrentUser = (state: ApplicationState) =>
    state.auth.currentUser;

let refetchCount = 0;
/**
 * Function that connects to get users and role for company api.
 */
function* handleGetUsersRequest({ payload }: any) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_USERS_AND_ROLE_FOR_COMPANY, {
                Name: filters.Name,
                Email: filters.Email,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * USERS_PAGE.pageSize,
                RoleIds: filters.Role,
            })
        );

        const { CompanyUserRoles } = get(res.data, 'GetUsersForCompany');
        const responsePayload = {
            data: CompanyUserRoles,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(CompanyUserRoles.length < pageSize) &&
                    !(pageSize < USERS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getUsersSuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getUsersRequestAction(payload));
        } else {
            const errorMessage =
                'Error fetching user list. Please try again later.';
            yield put(getUsersErrorAction({ errorMessages: [errorMessage] }));
        }
    }
}

/**
 * Function that connects to change user roles api.
 */
function* handleChangeUsersRoleRequest({ payload: sagaPayload }: any) {
    const { filter, userIds, roleId, excludeUsers, callback } = sagaPayload;
    const payload = {
        Filter: filter,
        RoleId: roleId,
        UserIds: userIds,
        ExcludeUsers: excludeUsers,
    };

    try {
        // To call async functions, use redux-saga's `call()`.
        yield call([API, 'post'], API_NAME, '/user/role/change', {
            body: payload,
        });

        yield put(changeUsersRoleResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
    } catch (err) {
        yield put(changeUsersRoleResponseAction());

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function that connects to get users and role and returns a user record based on name. Name checking should be equals instead of contains.
 */
function* handleGetUserByName({ payload }: any) {
    const { name, callback } = payload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_USERS_AND_ROLE_FOR_COMPANY, {
                Name: name,
                Email: '',
                SortField: 'Name',
                Ascending: true,
                PageSize: 10,
                Skip: 0,
            })
        );

        refetchCount = 0;

        const { CompanyUserRoles } = get(res.data, 'GetUsersForCompany');
        const filteredUser = get(
            filter(CompanyUserRoles, (userRole: DynamicObject) => {
                const givenName = get(userRole, 'User.GivenName');
                const familyName = get(userRole, 'User.FamilyName');
                if (givenName || familyName) {
                    const userFullName = `${givenName} ${familyName}`;

                    if (lowerCase(userFullName) === lowerCase(name)) {
                        return true;
                    }
                }
                return false;
            }),
            0
        );

        const response = {
            IsSuccess: !isEmpty(filteredUser),
            User: get(filteredUser, 'User'),
        };
        if (callback) {
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getUserByNameAction(name, callback));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function that connects to get users and role and returns a user record based on email. Email checking should be equals instead of contains.
 */
function* handleGetUserByEmail({ payload }: any) {
    const { email, callback } = payload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_USERS_AND_ROLE_FOR_COMPANY, {
                Name: '',
                Email: email,
                SortField: 'Name',
                Ascending: true,
                PageSize: 10,
                Skip: 0,
            })
        );

        refetchCount = 0;

        const { CompanyUserRoles } = get(res.data, 'GetUsersForCompany');
        const filteredUser = get(
            filter(CompanyUserRoles, (userRole: DynamicObject) => {
                const userEmail = get(userRole, 'User.Email');
                if (userEmail) {
                    if (toLower(userEmail) === toLower(email)) {
                        return true;
                    }
                }
                return false;
            }),
            0
        );

        const response = {
            IsSuccess: !isEmpty(filteredUser),
            User: get(filteredUser, 'User'),
        };
        if (callback) {
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getUserByEmailAction(email, callback));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function that connects to remove users api.
 */
function* handleRemoveUsersRequest({ payload: sagaPayload }: any) {
    const { filter, userIds, excludeUsers, callback } = sagaPayload;
    const payload = {
        Filter: filter,
        UserIds: userIds,
        ExcludeUsers: excludeUsers,
    };

    try {
        // To call async functions, use redux-saga's `call()`.
        yield call([API, 'post'], API_NAME, '/company/removeuser', {
            body: payload,
        });

        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function that connects to fetching of last successful login by user.
 */
function* handleGetUserLastLoginDate({ payload: callback }: any) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'get'],
            API_NAME,
            '/productannouncement/userlastlogindatetime',
            {}
        );

        const response = {
            ...res,
            IsSuccess: true,
        };

        callback(response);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function that connects to get users preferences api.
 */
function* handleGetUserPreferencesRequest({ payload: callback }: any) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_USER_PREFERENCE)
        );

        const UserPreference = get(res.data, 'GetUserPreference');

        refetchCount = 0;
        yield put(getUserPreferencesSuccessAction(UserPreference));
        if (callback)
            callback({
                IsSuccess: true,
                data: get(UserPreference, 'Preferences', []),
            });
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getUserPreferencesRequestAction(callback));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetUsersRequest() {
    yield takeLatest(UsersActionTypes.GET_USERS_REQUEST, handleGetUsersRequest);
}

function* watchChangeUsersRoleRequest() {
    yield takeLatest(
        UsersActionTypes.CHANGE_USERS_ROLE_REQUEST,
        handleChangeUsersRoleRequest
    );
}

function* watchGetUserByName() {
    yield takeLatest(UsersActionTypes.GET_USER_BY_NAME, handleGetUserByName);
}

function* watchGetUserByEmail() {
    yield takeLatest(UsersActionTypes.GET_USER_BY_EMAIL, handleGetUserByEmail);
}

function* watchRemoveUsersRequest() {
    yield takeLatest(
        UsersActionTypes.REMOVE_USERS_REQUEST,
        handleRemoveUsersRequest
    );
}

function* watchGetUserLastLoginDate() {
    yield takeLatest(
        UsersActionTypes.GET_USER_LAST_LOGIN_DATE,
        handleGetUserLastLoginDate
    );
}

function* watchGetUserPreferencesRequest() {
    yield takeLatest(
        UsersActionTypes.GET_USER_PREFERENCES_REQUEST,
        handleGetUserPreferencesRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* usersSaga() {
    yield all([
        fork(watchGetUsersRequest),
        fork(watchChangeUsersRoleRequest),
        fork(watchGetUserByName),
        fork(watchGetUserByEmail),
        fork(watchRemoveUsersRequest),
        fork(watchGetUserLastLoginDate),
        fork(watchGetUserPreferencesRequest),
    ]);
}

export default usersSaga;
