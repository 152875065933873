/**
 * Responsible for the content of create payment plan config .
 */

import { Button, Col, Form, Row, DatePicker, InputNumber, Select, Tabs, Card } from 'antd';
import { get, map, unset } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    paymentPlanConfigTypeOptions,
    INSTALMENT_TYPE,
    FORM_FIELDS,
    PAYMENT_DAY,
    TAB_NAME
} from '../../constants/paymentPlanConfigs'
import { dateFormatDDMMMYYYYSpace, dateFormatMMMYYYYSpace, dateFormatDDMMSlash } from '../../constants/dateFormats';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { limitOnlyNumber, getNextMonth, getLoadingSkeletons } from '../../utils/commonFunctions';
import moment from 'moment-timezone';
import { Company } from '../../store/companies/types';
import { PaymentPlansState } from '../../store/paymentPlans/types';
import CustomFieldsTabContent from '../../components/common/CustomFieldsTabContent';
import { ApplicationState } from '../../store';

const { Option } = Select;
const { TabPane } = Tabs;
const { Item: FormItem } = Form;

interface IProps {
    readonly form?: any;
    readonly setPaymentPlanConfigFormState: any;
    readonly paymentPlanConfigFormState: any;
    readonly setFormHasChanges: () => void;
    readonly paymentPlanOptionIndex: any;
    readonly selectedUserCompany: Company;
}

const CreatePaymentPlanConfigTabContent: React.FC<IProps> = ({
    form,
    setPaymentPlanConfigFormState,
    paymentPlanConfigFormState,
    setFormHasChanges,
    paymentPlanOptionIndex,
    selectedUserCompany
}: IProps) => {
    const { getFieldDecorator, resetFields, setFieldsValue } = form;
    const { RangePicker, MonthPicker } = DatePicker;
    const paymentPlansState: PaymentPlansState = useSelector(
        (state: ApplicationState) => state.paymentPlans
    );
    const { loading } = paymentPlansState;


    /**
      * Function that concatanate field names.
      */
    const generateFields = (fieldName: string, fieldIndex?: number | null) => {
        let field = `${fieldName}${paymentPlanOptionIndex}`;
        if (fieldIndex != null) {
            field = `${field}${fieldIndex}`;
        }

        return field;
    };

    //constants
    const customFieldsTabName: string = generateFields(TAB_NAME.CUSTOM_FIELDS);
    const selectedCustomFieldTabName: string = generateFields(TAB_NAME.SELECTED_CUSTOM_FIELD);
    const customFieldTabIdName: string = generateFields(TAB_NAME.CUSTOM_FIELD_ID);
    const customFieldTabKeysName: string = generateFields(TAB_NAME.CUSTOM_FIELD_KEYS);
    const customDefaultInstallments: number = 2;

    /**
     * Function that populates the dropdown options for payment day option.
     */
    const populatePaymentDayChildrenOptions = () =>
        map(
            PAYMENT_DAY,
            value => (
                <Option key={value} value={value}>
                    {value}
                </Option>
            )
        );

    const updateFormStateObject = (name: string, val: any, hasChanges?: boolean | false) => {
        setPaymentPlanConfigFormState((prev: any) => ({
            ...prev,
            [name]: val
        }));

        if (hasChanges) {
            setFormHasChanges();
        }
    };

    /**
        * Function that updates the form state and which form field will be updated.
        * @param name - field name
        * @param valueIdentifier - string, either value or checked, or others, based on e.target.value
        */
    const updateFormState: any = (
        name: string
    ) => {
        return (
            e: React.ChangeEvent<HTMLInputElement>
        ) => {
            let correctValue =
                get(e, `target.value`) ||
                get(e, `target.value`);

            if (correctValue == undefined) {
                correctValue = e;
            }

            updateFormStateObject(name, correctValue, true);
        };
    };


    /**
  * Function that populates the dropdown options for custom day option.
  */
    const populateCustomDayChildrenOptions = () =>
        map(
            Array.from({ length: 31 }, (_, i) => i + 1),
            i => (
                <Option key={i} value={i}>
                    {i}
                </Option>
            )
        );

    /**
      * Function that populates the accounting system dropdown options.
      */
    const populatePaymentPlanConfigTypeOptions = () => {
        return map(
            paymentPlanConfigTypeOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            )
        );
    };

    useEffect(() => {

        let displayDates = [];//ui display
        let formDates = [];//save format

        let instalments: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        let splitInstalmentPeriod: RangePickerValue | undefined = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE));
        if (instalments && splitInstalmentPeriod && splitInstalmentPeriod[0] && splitInstalmentPeriod[1]) {
            const startDate = splitInstalmentPeriod[0].toDate();
            const endDate = splitInstalmentPeriod[1].toDate();
            const dateDiff = endDate.getTime() - startDate.getTime();
            const interval = dateDiff / instalments;

            for (let i = 1; i <= instalments; i++) {
                displayDates.push(moment(new Date(startDate.getTime() + i * interval)).format(dateFormatDDMMMYYYYSpace));
                formDates.push(moment(new Date(startDate.getTime() + i * interval)).format(dateFormatDDMMSlash));
            }
        }

        let specificPaymentDayValue: number | undefined = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY));
        let fromMonthPaymentDay: any | null = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.FROM_MONTH));
        let paymentDay: string | null = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.PAYMENT_DAY));


        if (instalments && fromMonthPaymentDay && paymentDay) {
            if (paymentDay == PAYMENT_DAY.CUSTOM && !specificPaymentDayValue) return;

            const resolvePayDate = (inputDate: moment.Moment) => {
                if (paymentDay === PAYMENT_DAY.FIRST_DATE) return inputDate.startOf('month');
                if (paymentDay === PAYMENT_DAY.LAST_DATE) return inputDate.endOf('month');

                // Get the number of days in the month
                let daysInMonth = inputDate.daysInMonth();
                let currentSpecificPaymentDayValue = specificPaymentDayValue;

                // If the specified day is greater than the number of days in the month, use the last day of the month
                if (!currentSpecificPaymentDayValue || currentSpecificPaymentDayValue > daysInMonth) {
                    currentSpecificPaymentDayValue = daysInMonth;
                }
                // If the specified day is less than 1, use the first day of the month
                else if (currentSpecificPaymentDayValue < 1) {
                    currentSpecificPaymentDayValue = 1;
                }

                return inputDate.date(currentSpecificPaymentDayValue);
            };

            let currentDueDatetime = fromMonthPaymentDay;

            for (let i = 1; i <= instalments; i++) {
                if (i != 1) {
                    currentDueDatetime = getNextMonth(currentDueDatetime);
                }

                displayDates.push(resolvePayDate(currentDueDatetime).format(dateFormatDDMMMYYYYSpace));
                formDates.push(resolvePayDate(currentDueDatetime).format(dateFormatDDMMSlash));
            }
        }

        //set schedule dates
        if (displayDates.length > 0 && formDates.length > 0) {
            updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULE_DATES), formDates);
            updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULE_DATES_DISPLAY), displayDates);
        }

    }, [
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)],
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE)],
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.FROM_MONTH)],
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY)],
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.PAYMENT_DAY)]]);

    function handleChangeInstalmentPeriod(dates: RangePickerValue) {
        updateFormStateObject(generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE), dates, true);
    }

    function handleChangeInstalments(instalment: number | undefined) {
        if (instalment && instalment >= 2 && instalment <= 30) {
            updateFormStateObject(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), instalment, true);
        } else {
            updateFormStateObject(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), null, true);
        }
    }

    function handleSelectFromMonthPaymentDay(fromMonth: any) {
        updateFormStateObject(generateFields(FORM_FIELDS.FROM_MONTH), fromMonth, true);
    }

    function handleSelectPaymentDay(paymentDay: any) {
        updateFormStateObject(generateFields(FORM_FIELDS.PAYMENT_DAY), paymentDay, true);
    }

    function handleSelectCustomPaymentDay(customPaymentDay: any) {
        updateFormStateObject(generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY), customPaymentDay, true);
    }

    function handleSelectPaymentPlanConfigTypeSelect(paymentPlanConfigType: any) {

        //unset fields states
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATES));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATES_DISPLAY));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.FROM_MONTH));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.PAYMENT_DAY));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.DEPOSIT_PERCENTAGE));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.DEPOSIT_AMOUNT));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.DEPOSIT_GAP));

        //remove custom field state
        let keys: number[] = get(paymentPlanConfigFormState, customFieldTabKeysName);
        if (keys && keys.length > 0) {
            map(keys, (key) => {
                unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.CUSTOM_FIELD_NAME, key));
                unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.CUSTOM_FIELD_VALUE, key));
            });
        }


        //remove  schedule dates state
        let scheduleDatesKey: number[] = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
        if (scheduleDatesKey && scheduleDatesKey.length > 0) {
            map(scheduleDatesKey, (key) => {
                unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULED_DATE, key));

            });
            unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
        }

        //init custom field tabs
        updateFormStateObject(customFieldTabIdName, 0);
        updateFormStateObject(customFieldsTabName, []);
        updateFormStateObject(customFieldTabKeysName, []);
        updateFormStateObject(selectedCustomFieldTabName, '');

        //set value
        updateFormStateObject(generateFields(FORM_FIELDS.INSTALMENT_TYPE), paymentPlanConfigType);
        if (paymentPlanConfigType == INSTALMENT_TYPE.CUSTOM) {

            //set default number of installment
            setFieldsValue({ [generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)]: customDefaultInstallments });
            updateFormStateObject(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), customDefaultInstallments);

            //always state custom type with 2 schedule dates
            let keys: number[] = [1, 2];
            updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS), keys);
        }

        resetFields(
            generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)
        );


    }

    const addPaymentPlanScheduleDate = () => {
        let numberOfInstallments: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        let newNumberOfInstallments: number = numberOfInstallments + 1;
        let keys: number[] = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
        let newKey: number = keys[numberOfInstallments - 1] + 1//prevent duplicate key
        keys.push(newKey);

        updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS), keys);

        //update number of installments
        setFieldsValue({ [generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)]: newNumberOfInstallments });
        updateFormStateObject(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), newNumberOfInstallments);
    };

    const removeScheduleDate = (key: number) => {
        let keys: number[] = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
        const newKeys = keys.filter((value) => value != key);

        //remove selected schedule date
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULED_DATE, key));
        updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS), newKeys);

        //update number of installments
        let numberOfInstallments: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        setFieldsValue({ [generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)]: numberOfInstallments - 1 });
        updateFormStateObject(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), numberOfInstallments - 1);
    };

    const populateAddedScheduleDates = () => {
        const formItems = paymentPlanConfigFormState[generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS)].map((k: any) => (
            <div key={k} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <FormItem key={k}>
                    {getFieldDecorator(generateFields(FORM_FIELDS.SCHEDULED_DATE, k), {
                        rules: [
                            {
                                required: true,
                                message: 'Scheduled dates required!',
                            }
                        ],
                        initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.SCHEDULED_DATE, k)]
                    })(<DatePicker
                        format={dateFormatDDMMMYYYYSpace}
                        placeholder="Date"
                        onChange={updateFormState(
                            generateFields(FORM_FIELDS.SCHEDULED_DATE, k)
                        )}
                    />

                    )}
                </FormItem>
                {k > 2 ? (<Button type="link"  style={{ marginLeft: 10 }} onClick={() => removeScheduleDate(k)}>remove</Button>) : ''}
            </div>
        ));

        return formItems;
    };

    const populateScheduleDates = () => {
        return (
            <>
                <Row className="mt-9">
                    <Col span={24}>
                        <Card>
                            <Col span={5}>
                                <h4>Schedule dates:</h4>
                                <Button type="primary" onClick={() => addPaymentPlanScheduleDate()}>Add schedule dates</Button>
                            </Col>
                            <Col span={10}>
                                {
                                    paymentPlanConfigFormState[generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS)] ?
                                        populateAddedScheduleDates()
                                        : ''
                                }
                            </Col>
                        </Card>
                    </Col>
                </Row>

            </>
        );
    }

    const addCustomFieldTab = () => {
        let customFieldsTabId: number = get(paymentPlanConfigFormState, customFieldTabIdName);
        let tabs: any[] = get(paymentPlanConfigFormState, customFieldsTabName);
        tabs.push({
            key: `custom-field-dynamic-${customFieldsTabId}`,
            title: 'Custom Field ' + (customFieldsTabId + 1),
            component: CustomFieldsTabContent,
            closable: true
        });

        //add new tab
        updateFormStateObject(customFieldsTabName, tabs);


        let keys: number[] = get(paymentPlanConfigFormState, customFieldTabKeysName);
        keys.push(customFieldsTabId);

        //update details
        updateFormStateObject(customFieldTabKeysName, keys);
        updateFormStateObject(customFieldTabIdName, customFieldsTabId + 1);
        updateFormStateObject(selectedCustomFieldTabName, "custom-field-dynamic-" + customFieldsTabId);

        setFormHasChanges();
    };

    const removeCustomFieldTab = (targetKey: any) => {

        //remove fields
        const tabIndex = targetKey.split('-')[3];
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.CUSTOM_FIELD_NAME, tabIndex));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.CUSTOM_FIELD_VALUE, tabIndex));

        //update state
        let keys: number[] = get(paymentPlanConfigFormState, customFieldTabKeysName);
        const newTabKeys = keys.filter((key) => key != tabIndex);
        updateFormStateObject(customFieldTabKeysName, newTabKeys);

        //remove selected custom field tab
        let tabs: any[] = get(paymentPlanConfigFormState, customFieldsTabName);
        const newTabs = tabs.filter((pane) => pane.key !== targetKey);
        updateFormStateObject(customFieldsTabName, newTabs);

        // If the active tab is being removed, switch to the next tab or the previous one
        if (get(paymentPlanConfigFormState, selectedCustomFieldTabName) === targetKey && newTabs.length > 0) {
            // If the tab to be removed is active, set the new active key 
            updateFormStateObject(selectedCustomFieldTabName, newTabs[0].key);
        }
    };

    const handleCustomFieldEditTab = (targetKey: string | React.MouseEvent<Element, MouseEvent>, action: "add" | "remove") => {
        if (action === 'add') {
            addCustomFieldTab();  // Call the add function when action is 'add'
        } else if (action === 'remove') {
            removeCustomFieldTab(targetKey);
        }
    };

    /**
    * Function called when the active payment plan option tab (clicking on tab item - title)
    * @param value
    */
    const handleCustomFieldTabChange = (value: string) => {
        updateFormStateObject(selectedCustomFieldTabName, value);
    };

    return (
        <>
            <Form className="form-inline-mb-0">
                <Row>
                    <Col span={5}>
                        <FormItem label='Instalment type'>
                            {getFieldDecorator(generateFields(FORM_FIELDS.INSTALMENT_TYPE), {
                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)],
                                rules: [
                                    {
                                        required: true,
                                        message: 'Instalment Type is required!',
                                    }
                                ]
                            })(<Select onChange={handleSelectPaymentPlanConfigTypeSelect}>
                                {populatePaymentPlanConfigTypeOptions()}
                            </Select>)}
                        </FormItem>
                    </Col>

                </Row>
                {paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)] == INSTALMENT_TYPE.DEFAULT ?
                    (<Row><Col span={5}>
                        <FormItem label='Instalment periods'>
                            {getFieldDecorator(generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE), {
                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE)],
                                rules: [
                                    {
                                        required: true,
                                        message: 'Instalment periods required!',
                                    }
                                ]
                            })(<RangePicker
                                format={dateFormatDDMMMYYYYSpace}
                                onChange={handleChangeInstalmentPeriod} />)}
                        </FormItem>
                    </Col></Row>) : ''
                }

                {paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)] == INSTALMENT_TYPE.MONTHLY ?
                    (
                        <>
                            <Row>
                                <Col span={5}>
                                    <FormItem label='From Month'>
                                        {getFieldDecorator(generateFields(FORM_FIELDS.FROM_MONTH), {
                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.FROM_MONTH)],
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'From month required!',
                                                }
                                            ]
                                        })(<MonthPicker
                                            onChange={handleSelectFromMonthPaymentDay}
                                            style={{ width: 200 }}
                                            placeholder='Select month'
                                            format={dateFormatMMMYYYYSpace} />)}
                                    </FormItem>
                                </Col></Row>
                            <Row>
                                <Col span={5}>
                                    <FormItem label='Choose Payment Day'>
                                        {getFieldDecorator(generateFields(FORM_FIELDS.PAYMENT_DAY), {
                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.PAYMENT_DAY)],
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Choose Payment Day required!',
                                                }
                                            ]
                                        })(
                                            <Select onChange={handleSelectPaymentDay}>
                                                {populatePaymentDayChildrenOptions()}
                                            </Select>)}
                                    </FormItem>
                                </Col>
                            </Row>
                            {paymentPlanConfigFormState[generateFields(FORM_FIELDS.PAYMENT_DAY)] === PAYMENT_DAY.CUSTOM && (
                                <Row>
                                    <Col span={5}>
                                        <FormItem label="Specify Custom Day">
                                            {getFieldDecorator(generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY), {
                                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY)],
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Specify Custom Day required!',
                                                    }
                                                ]
                                            })(
                                                <Select showSearch onChange={handleSelectCustomPaymentDay} >
                                                    {populateCustomDayChildrenOptions()}
                                                </Select>)}
                                        </FormItem>
                                    </Col></Row>
                            )}
                        </>
                    ) : ''
                }

                {paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)] != null ?
                    (
                        <>
                            <Row>
                                <Col span={5}>
                                    <FormItem label='Number of instalments'>
                                        {getFieldDecorator(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Number of instalments required!'
                                                },
                                                {
                                                    validator: (rule: any, value: any, callback: any) => {
                                                        if (value && (value < 2 || value > 30)) {
                                                            callback('The number of instalments should be between 2 and 30.');
                                                        } else {
                                                            callback();
                                                        }
                                                    }
                                                }
                                            ],
                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)]
                                        })(
                                            <InputNumber
                                                min={2}
                                                max={30}
                                                onChange={handleChangeInstalments}
                                                onKeyDown={limitOnlyNumber(false)}
                                                disabled={paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)] == INSTALMENT_TYPE.CUSTOM ? true : false}
                                                style={{width:200}}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={5}>
                                    <FormItem label="Deposit Gap">
                                        {getFieldDecorator(generateFields(FORM_FIELDS.DEPOSIT_GAP), {
                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.DEPOSIT_GAP)]
                                        })(
                                            <InputNumber
                                                min={1}
                                                max={100}
                                                onKeyDown={limitOnlyNumber(false)}
                                                style={{ width: 200 }}
                                                onChange={updateFormState(
                                                    generateFields(FORM_FIELDS.DEPOSIT_GAP)
                                                )}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={5}>
                                    <FormItem label="Deposit percentage">
                                        {getFieldDecorator(generateFields(FORM_FIELDS.DEPOSIT_PERCENTAGE), {
                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.DEPOSIT_PERCENTAGE)]
                                        })(
                                            <InputNumber
                                                min={1}
                                                max={100}
                                                onKeyDown={limitOnlyNumber(false)}
                                                style={{ width: 200 }}
                                                onChange={updateFormState(
                                                    generateFields(FORM_FIELDS.DEPOSIT_PERCENTAGE)
                                                )}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={5}>
                                    <FormItem label="Deposit amount">
                                        {getFieldDecorator(generateFields(FORM_FIELDS.DEPOSIT_AMOUNT), {
                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.DEPOSIT_AMOUNT)]
                                        })(
                                            <InputNumber
                                                min={1}
                                                style={{ width: 200 }}
                                                onChange={updateFormState(
                                                    generateFields(FORM_FIELDS.DEPOSIT_AMOUNT)
                                                )}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>

                            {paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)] == INSTALMENT_TYPE.CUSTOM ? populateScheduleDates() : ''}

                            {paymentPlanConfigFormState[generateFields(FORM_FIELDS.SCHEDULE_DATES_DISPLAY)] && paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)] != INSTALMENT_TYPE.CUSTOM ?
                                (<Row><Col>
                                    <h4>Schedule Dates:</h4>
                                    <ul>
                                        {
                                            map(
                                                paymentPlanConfigFormState[generateFields(FORM_FIELDS.SCHEDULE_DATES_DISPLAY)],
                                                (value) => (
                                                    <li key={value}>{value}</li>
                                                )
                                            )
                                        }
                                    </ul>

                                </Col></Row>)

                                : ''
                            }

                            <Row>
                                <h4>Custom Fields:</h4>
                                <Button type="primary" style={{ marginBottom: 10 }} onClick={addCustomFieldTab}>Add Custom Field</Button>
                                <Tabs
                                    onChange={handleCustomFieldTabChange}
                                    activeKey={paymentPlanConfigFormState[selectedCustomFieldTabName]}
                                    type="editable-card"
                                    onEdit={(targetKey, action) => handleCustomFieldEditTab(targetKey, action)}
                                    destroyInactiveTabPane={false}
                                    hideAdd
                                >
                                    {map(
                                        paymentPlanConfigFormState[customFieldsTabName],
                                        ({
                                            component: TabItemComponent,
                                            ...option
                                        }) => {
                                            let keyIndex = option.key.toString().split('-')[3];
                                            return (
                                                <TabPane
                                                    tab={
                                                        option.title
                                                    }
                                                    key={option.key}
                                                    closable={option.closable}
                                                >
                                                    {paymentPlanConfigFormState[selectedCustomFieldTabName] === option.key &&
                                                        (loading ? (
                                                            getLoadingSkeletons(
                                                                3,
                                                                loading
                                                            )
                                                        ) : (
                                                            <TabItemComponent
                                                                form={form}
                                                                setPaymentPlanConfigFormState={setPaymentPlanConfigFormState}
                                                                paymentPlanConfigFormState={paymentPlanConfigFormState}
                                                                setFormHasChanges={setFormHasChanges}
                                                                paymentPlanOptionIndex={paymentPlanOptionIndex}
                                                                selectedUserCompany={selectedUserCompany}
                                                                tabIndex={keyIndex}
                                                            />
                                                        ))}
                                                </TabPane>
                                            )
                                        }
                                    )}
                                </Tabs>
                            </Row>
                        </>
                    ) : ''
                }
            </Form>
        </>
    );
};


export default CreatePaymentPlanConfigTabContent;