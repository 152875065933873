import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    Conversation,
    ConversationLine,
    GetConversationRequestPayload,
    PageData,
} from '../common/types';
import { Invoice } from '../invoices/types';
import { Task } from '../tasks/types';
import {
    GetTicketDataInvoicesRequestPayload,
    GetTicketDataTasksRequestPayload,
    GetTicketDiscussionRequestPayload,
    GetTicketInfoForChatValidationPayload,
    GetTicketsRequestPayload,
    TicketAddCommentRequestPayload,
    TicketResourceReplyRequestPayload,
    TicketsActionTypes,
    TicketAssignUserRequestPayload,
} from './types';

/**
 * Actions for getting the tickets data to be used in `Customer Assistance` page.
 * @param payload
 */
export const getTicketsRequestAction = (payload: GetTicketsRequestPayload) =>
    action(TicketsActionTypes.GET_TICKETS_REQUEST, payload);
export const getTicketsSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
    mergeData?: boolean;
}) => action(TicketsActionTypes.GET_TICKETS_SUCCESS, payload);
export const getTicketsErrorAction = (payload: string[]) =>
    action(TicketsActionTypes.GET_TICKETS_ERROR, payload);

/**
 *  Action for updating the filter values for tickets.
 * @param filters
 */
export const updateTicketsFiltersAction = (
    filters: GetTicketsRequestPayload['filters']
) => action(TicketsActionTypes.UPDATE_TICKETS_FILTERS, filters);

/**
 * Action for updating the sort values for tickets.
 * @param tableSortAndStatus
 */
export const updateTicketsSortByAndStatusAction = (tableSortAndStatus: {
    sortBy: GetTicketsRequestPayload['sortBy'];
    sortAscending: boolean;
    ticketStatus: number;
}) =>
    action(
        TicketsActionTypes.UPDATE_TICKETS_SORT_BY_AND_STATUS,
        tableSortAndStatus
    );

/**
 * Action called when the table filter value for the upper left dropdown
 * changes.
 * @param tableFilter
 */
export const updateTicketsTableFilterAction = (tableFilter: string) =>
    action(TicketsActionTypes.UPDATE_TICKETS_TABLE_FILTER_STATUS, tableFilter);

/**
 * Action for resetting table filters and sort values.
 */
export const clearTicketsStateAllTableFiltersAction = () =>
    action(TicketsActionTypes.CLEAR_TICKETS_STATE_ALL_TABLE_FILTERS);

/**
 * Action called for clearing all the state for the tickets.
 */
export const clearTicketsStateDataAction = () =>
    action(TicketsActionTypes.CLEAR_TICKETS_STATE_DATA);

// Single Record
/**
 * Actions called for setting the selectedId when clicking on a ticket item.
 * @param ticketId
 * @param callback
 */
export const setSelectedTicketIdRequestAction = (
    ticketId: string | null,
    callback: () => void
) =>
    action(TicketsActionTypes.SET_TICKET_SELECTED_ID_REQUEST, {
        ticketId,
        callback,
    });
export const setSelectedTicketIdSuccessAction = (ticketId: string | null) =>
    action(TicketsActionTypes.SET_TICKET_SELECTED_ID_SUCCESS, ticketId);

/**
 * Actions called when getting the ticket details based on the given ticketId.
 * @param ticketId
 * @param isUsingCloudImportType
 */
export const getTicketDataRequestAction = (
    ticketId: string,
    isUsingCloudImportType: boolean
) =>
    action(TicketsActionTypes.GET_TICKET_DATA_REQUEST, {
        ticketId,
        isUsingCloudImportType,
    });
export const getTicketDataSuccessAction = (payload: { record: {} }) =>
    action(TicketsActionTypes.GET_TICKET_DATA_SUCCESS, payload);
export const getTicketDataErrorAction = (payload: string[]) =>
    action(TicketsActionTypes.GET_TICKET_DATA_ERROR, payload);

/**
 * Action called for clearing all the data state for ticket details.
 */
export const clearTicketDataAction = () =>
    action(TicketsActionTypes.CLEAR_TICKET_DATA_SUCCESS);

// Invoices
/**
 * Actions called for getting the invoices data for tickets.
 * @param payload
 */
export const getTicketDataInvoicesRequestAction = (
    payload: GetTicketDataInvoicesRequestPayload
) => action(TicketsActionTypes.GET_TICKET_DATA_INVOICES_REQUEST, payload);
export const getTicketDataInvoicesSuccessAction = (payload: {
    data: Invoice[];
    pageData: PageData;
}) => action(TicketsActionTypes.GET_TICKET_DATA_INVOICES_SUCCESS, payload);
export const getTicketDataInvoicesErrorAction = (payload: string[]) =>
    action(TicketsActionTypes.GET_TICKET_DATA_INVOICES_ERROR, payload);

/**
 * Action for updating the filter values to be used in getting the Invoices data for tickets.
 * @param filters
 */
export const updateTicketDataInvoicesFiltersAction = (
    filters: GetTicketDataInvoicesRequestPayload['filters']
) => action(TicketsActionTypes.UPDATE_TICKET_DATA_INVOICES_FILTERS, filters);

/**
 * Action called for updating the Invoice state and sort values when getting the invoices for tickets.
 * @param tableSort
 */
export const updateTicketDataInvoicesSortByAndStateAction = (tableSort: {
    sortBy: GetTicketDataInvoicesRequestPayload['sortBy'];
    sortAscending: boolean;
    invoiceState: string;
}) =>
    action(
        TicketsActionTypes.UPDATE_TICKET_DATA_INVOICES_SORT_BY_AND_STATE,
        tableSort
    );

// Tasks
/**
 * Actions called for getting the tasks data for the selected ticket.
 * @param payload
 */
export const getTicketDataTasksRequestAction = (
    payload: GetTicketDataTasksRequestPayload
) => action(TicketsActionTypes.GET_TICKET_DATA_TASKS_REQUEST, payload);
export const getTicketDataTasksSuccessAction = (payload: {
    data: Task[];
    pageData: PageData;
}) => action(TicketsActionTypes.GET_TICKET_DATA_TASKS_SUCCESS, payload);
export const getTicketDataTasksErrorAction = (payload: string[]) =>
    action(TicketsActionTypes.GET_TICKET_DATA_TASKS_ERROR, payload);

// export const updateTicketDataTasksFiltersAction = (
//     filters: GetTicketDataTasksRequestPayload['filters']
// ) => action(TicketsActionTypes.UPDATE_TICKET_DATA_TASKS_FILTERS, filters);
// export const updateTicketDataTasksSortByAction = (tableSort: {
//     sortBy: GetTicketDataTasksRequestPayload['sortBy'];
//     sortAscending: boolean;
// }) => action(TicketsActionTypes.UPDATE_TICKET_DATA_TASKS_SORT_BY, tableSort);

/**
 * Function for getting the Options for the `Action` options on resolving ticket panel.
 * @param workflowId
 * @param stateName
 * @param callback
 */
export const getResolveTicketOptionsRequestAction = (
    companyId: string,
    workflowId: string,
    stateName: string,
    callback?: (response: any) => void
) =>
    action(TicketsActionTypes.GET_RESOLVE_TICKET_OPTIONS_REQUEST, {
        companyId,
        workflowId,
        stateName,
        callback,
    });

/**
 * Action called when submitting the form when resolving a ticket task.
 * @param payload
 */
export const resolveQueryRequestAction = (payload: {}) =>
    action(TicketsActionTypes.RESOLVE_QUERY_REQUEST, payload);
export const resolveOrganisationQueryRequestAction = (payload: {}) =>
    action(TicketsActionTypes.ORGANISATION_RESOLVE_QUERY_REQUEST, payload);

// Conversation actions
export const getTicketConversationRequestAction = (
    payload: GetConversationRequestPayload
) => action(TicketsActionTypes.GET_TICKET_CONVERSATION_REQUEST, payload);
export const getTicketConversationSuccessAction = (payload: {
    data: Conversation[];
    pageData: PageData;
}) => action(TicketsActionTypes.GET_TICKET_CONVERSATION_SUCCESS, payload);
export const getTicketConversationErrorAction = (payload: string[]) =>
    action(TicketsActionTypes.GET_TICKET_CONVERSATION_ERROR, payload);

export const updateTicketConversationFiltersAction = (
    filters: GetConversationRequestPayload['filters']
) => action(TicketsActionTypes.UPDATE_TICKET_CONVERSATION_FILTERS, filters);

export const updateTicketConversationTableFilterAction = (
    conversationTableFilter: string
) =>
    action(
        TicketsActionTypes.UPDATE_TICKET_CONVERSATION_TABLE_FILTER,
        conversationTableFilter
    );
export const updateTicketConversationSortByAction = (tableSort: {
    sortBy: GetConversationRequestPayload['sortBy'];
    sortAscending: boolean;
}) => action(TicketsActionTypes.UPDATE_TICKET_CONVERSATION_SORT_BY, tableSort);
/**
 * Action called when adding ticket comment.
 * @param payload
 */
export const ticketAddCommentRequestAction = (
    payload: TicketAddCommentRequestPayload
) => action(TicketsActionTypes.TICKET_ADD_COMMENT_REQUEST, payload);

/**
 * Action called when requesting resource reply.
 * @param payload
 */
export const ticketResourceReplyRequestAction = (
    payload: TicketResourceReplyRequestPayload
) => action(TicketsActionTypes.TICKET_RESOURCE_REPLY_REQUEST, payload);

export const getTicketDiscussionRequestAction = (
    payload: GetTicketDiscussionRequestPayload
) => action(TicketsActionTypes.GET_TICKET_DISCUSSION_REQUEST, payload);
export const getTicketDiscussionSuccessAction = (payload: {
    data: ConversationLine[];
    pageData: PageData;
}) => action(TicketsActionTypes.GET_TICKET_DISCUSSION_SUCCESS, payload);
export const getTicketDiscussionErrorAction = (payload: string[]) =>
    action(TicketsActionTypes.GET_TICKET_DISCUSSION_ERROR, payload);
export const clearTicketDiscussionAction = () => action(TicketsActionTypes.CLEAR_TICKET_DISCUSSION);

/**
 * Action called when ticket assign user.
 * @param payload
 */
export const ticketAssignUserRequestAction = (
    payload: TicketAssignUserRequestPayload
) => action(TicketsActionTypes.TICKET_ASSIGN_USER_REQUEST, payload);

/** 
 * Action called when validating ticket for chat reply.
 * @param payload
 */
export const getTicketInfoForChatValidation = (payload: GetTicketInfoForChatValidationPayload) =>
    action(TicketsActionTypes.GET_TICKET_INFO_FOR_CHAT_VALIDATION_REQUEST, payload);

/**
* Function for getting the Ticket Custom Fields for a company.
* @param payload
*/
export const getTicketCustomFieldsForCompanyRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(TicketsActionTypes.GET_TICKET_CUSTOM_FIELDS_FOR_COMPANY_REQUEST, {
        companyId,
        callback,
    });
