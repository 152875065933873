import { Button, Col, Form, Modal, Row, Select, Upload, Typography,Input, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import { get, includes, isEmpty, map } from 'lodash';
import { getRolePermissions } from '../../store/roles/sagas';
import { ApplicationState } from '../../store';
import { CompaniesState, CompanyUserRole } from '../../store/companies/types';
import { DynamicObject } from '../../utils/commonInterfaces';
import React, { useState, useRef } from 'react';
import {
    submitSupportTicketRequestAction,
    attachSupportTicketRequestAction
} from '../../store/supportTickets/actions';
import { getPopoverContainer } from '../../utils/commonFunctions';

import { UploadProps, UploadFile } from 'antd/lib/upload/interface';
const { Title } = Typography;
const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;

interface IProps {
    form: DynamicObject;
    history: {
        push: (path: string) => void;
    };
}

const SupportTicketManagementPage: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const drawerRef: any = useRef(null);
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue } = props.form;

    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );
    
    const selectedUserCompany: CompanyUserRole | undefined =
        companiesState.selectedUserCompany;
    const userRole = get(selectedUserCompany, 'Role.Name');
    const rolePermissions = useSelector(getRolePermissions);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

    const priorityLevelOptions = [
        'Low',
        'Medium',
        'High',
    ];

    const allowedFileTypes = [
        '.png',
        '.gif',
        '.jpg',
        '.jpeg',
        '.txt',
        '.doc',
        '.docx',
        '.pdf',
        '.xls',
        '.xlsx',
    ];

    /**
    * Function for clearing the fileLists state.
    */
    const clearFileListObject = () => {
        setFileList([]);
    };

    const uploadProps: UploadProps = {
        accept: allowedFileTypes.join(','),
        multiple: true,
        onRemove: (file: UploadFile) => {
            setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
        },
        beforeUpload: (file: UploadFile) => {
            setFileList((prevList) => [...prevList, file]);
            return false;
        },
        showUploadList:false,
        fileList,
    };

    /**
     * Function called when `Save` button is clicked and will send all the changes to API.
     */
    const onSaveButtonClick = () => {
        validateFields((err: DynamicObject, values: DynamicObject) => {
            if (!err) {
                setSubmitLoading(true);
                const fileListsPayload:any = [];
                var fileExceeds:boolean = false;
                const filesNotValid:any = [];
                
                if(fileList.length > 10) {
                    setSubmitLoading(false);

                    return showModalError({
                        title: 'Number of files limit',
                        content: <div>The maximum allowed number of files is 10. Please reduce the number of attached files.</div>,
                    });
                }
                

                fileList.map((file: any, index) => {  
                    if(!allowedFileTypes.includes('.'+file.name.split('.').pop())){
                        filesNotValid.push(file)
                    }
                    const exceeds10Mb = file.size / 1024 / 1024 > 4;
                    if (exceeds10Mb) {
                        fileExceeds = true;
                    }      
                    const filePayload = {
                        UId: file.uid,
                        Title: file.name.substring(0, file.name.lastIndexOf('.')),
                        FileName: file.name
                    };

                    fileListsPayload.push(filePayload)
                })

                if (filesNotValid.length > 0) {
                    setSubmitLoading(false);
                    return showModalError({
                        title: 'Type error',
                        content: 'File type not valid!',
                    });
                }

                if(fileExceeds) {
                    setSubmitLoading(false);
                    return showModalError({
                        title: 'File size limit',
                        content: <div>File size must not exceed 4MB!</div>,
                    });
                }
                
                const payload = {
                    Subject: get(values,'subject'),
                    Description: get(values,'description'),
                    Priority: get(values,'priority'),
                    FileLists: fileListsPayload
                };
                
                dispatch(
                    submitSupportTicketRequestAction(payload,
                        (res: DynamicObject) => {
                            if(res.IsSuccess){
                                if(res.FileURLLists != null && res.FileURLLists.length > 0) {
                                    res.FileURLLists.map((file:any) => {
                                        const fileToUpload = fileList.find(fileItem => fileItem.uid == file.uid)
        
                                        uploadFileUsingPresignedUrl(
                                            file.attachmentURL,
                                            fileToUpload
                                        );
                                    })
                                
                                    attachmentFileToSupportTicket(res.Id,res.FileURLLists);
                                } 
                                else {
                                    handleModalSaveResponse(res.IsSuccess)
                                }
                            } else {
                                handleModalSaveResponse(res.IsSuccess)
                            }
                        })     
                );
            }
        });
    };

    /**
     * Function responsible for showing the success/error modal after saving the changes made.
     * @param IsSuccess - if the saving of data is successful
     * @param lastSavedPayload - latest saved values
     */
    const handleModalSaveResponse = (IsSuccess: boolean) => {
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: `Support ticket has been successfully created!`,
                onOk: () => {
                    resetFields();
                    setTimeout(() => {
                        clearFileListObject();
                    }, 200);
                },
            });
        } else {
            resetFields();
            clearFileListObject();
            Modal.error({
                title: 'Error',
                content: `Failed to submit your ticket`,
            });
            
        }
        setSubmitLoading(false);
    };


    /**
     * Function for update workflow timing definition
     */
    const attachmentFileToSupportTicket = (ticketId: string, fileAttachmentURLs: any[]) => {
        try {
                const payload = {
                    TicketId: ticketId,
                    FileURLs:fileAttachmentURLs
                };
                    
                dispatch(
                    attachSupportTicketRequestAction(
                        payload,
                        (res: DynamicObject) => {
                            handleModalSaveResponse(res.IsSuccess)
                        }
                    )
                );
        } catch (error) {
            console.error('Error attaching file to support ticket:', error);
            return [];
        }
    };

    /**
     * Function called when uploading the file using presigned url - fetched from backend.
     * @param preSignedUrl - presigned url from backend
     */
    const uploadFileUsingPresignedUrl = async (preSignedUrl: string, file:any) => {
        fetch(preSignedUrl, {
            method: 'PUT',
            body: file,
        })    
        .catch(() => {

            showModalError({
                title: 'Error',
                content: 'Failed to upload the attachment!',
            });
        });
    };

    const handleRemoveFile = (uid:string) => {
        setFileList(fileList.filter(file => file.uid !== uid));
    };

    /**
     * Common function for showing modal error.
     * @param modalObject
     */
    const showModalError = (modalObject: {}) => {
        Modal.error({
            ...modalObject,
            getContainer: () => getPopoverContainer(drawerRef),
        });
    };

    return (
        <div className="h-100">
            <Col span={24}>
                    <QueueAnim type={['right', 'left']} leaveReverse>
                        <Row key="title-container">
                            <Col span={24}>
                                <Title level={3}>Support Ticket</Title>
                            </Col>
                        </Row>
                        <br />
                        <Row
                            key="business-contact-container mb-10"
                            gutter={{ lg: 10 }}
                        >
                            <Col xl={18} lg={24}>
                                <Row gutter={{ lg: 10 }}>
                                    <Col xl={24} lg={24}>
                                        <Row className="mt-10">
                                            <Col span={24}>
                                                <Form
                                                    className="company-form"
                                                    labelCol={{
                                                        xxl: { span: 5 },
                                                        xl: { span: 10 },
                                                        lg: { span: 6 },
                                                    }}
                                                    wrapperCol={{
                                                        xxl: { span: 19 },
                                                        xl: { span: 14 },
                                                        lg: { span: 18 },
                                                    }}
                                                >
                                                    <Row gutter={10}>
                                                        <Col xl={24} sm={24}>
                                                            <FormItem label="Subject" labelCol={{ span: 5 }}>
                                                            {getFieldDecorator(
                                                                'subject',
                                                                {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: 'Title required!',
                                                                        },
                                                                    ],
                                                                }
                                                            )(
                                                                <Input />
                                                            )}
                                                                
                                                            </FormItem>
                                                            <FormItem label="Description" labelCol={{ span: 5 }}>
                                                                {getFieldDecorator(
                                                                    'description',
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: 'Description required!',
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <TextArea rows={5} maxLength={100}/>
                                                                )}
                                                                
                                                            </FormItem>
                                                            <FormItem label="Priority Level" labelCol={{ span: 5 }}>
                                                                {getFieldDecorator(
                                                                    'priority',
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: 'Priority required!',
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Select>
                                                                        {map(
                                                                            priorityLevelOptions,
                                                                            (option) => (
                                                                                <Option key={option} value={option}>
                                                                                    {option}
                                                                                </Option>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                )}                                       
                                                            </FormItem>
                                                        </Col>                                                   
                                                    </Row>                                      
                                                </Form>
                                            </Col>
                                            <Col span={24} className="ta-right">
                                                <Upload {...uploadProps} fileList={fileList}>
                                                    <Button type="primary">Upload file</Button>
                                                </Upload>
                                                {!fileList.length ? (
                                                    <span className="ml-10">No file selected</span>
                                                ) : (
                                                    <div className="file-list">
                                                        {fileList.map((file,index)=>(
                                                            <div key={file.uid} className="file-item">
                                                                <span className="file-name">
                                                                    {file.name} &nbsp;
                                                                    <span
                                                                        className="file-remove-icon"
                                                                        onClick={() => handleRemoveFile(file.uid)}
                                                                    >
                                                                        <Icon type="close-circle" />
                                                                    </span>
                                                                </span>
                                                                
                                                            </div>
                                                        ))}
                                                    </div>
                                                    
                                                )}
                                            </Col>                                         
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-16"></Col>
                        </Row>
           
                    </QueueAnim>
                {/* </Form> */}
                {submitLoading && (
                    <ModalWithSpinner
                        modalTitle="Submitting your ticket"
                        modalVisible={submitLoading}
                        displayMessage="Please wait while your ticket is being submitted. . ."
                    />
                )}
            </Col>
            <Col span={20} className="ta-right mt-16" style={{marginTop: '100px'}}>
                <Button
                    className="mr-10 w-100px"
                    type="primary"
                    onClick={onSaveButtonClick}
                    loading={submitLoading}
                >
                    Submit
                </Button>
            </Col>
        </div>
    );
};

const SupportTicketManagementPageForm = Form.create({
    name: 'support-ticket-management-page-form',
})(SupportTicketManagementPage);
export default withRouter(SupportTicketManagementPageForm);