import { ConversationLine } from "../common/types";

export enum ConversationLineActionTypes {
    FETCH_COMMUNICATION_RESOURCE_RESPONSE_DETAILS = '@@conversationLine/FETCH_COMMUNICATION_RESOURCE_RESPONSE_DETAILS',
    SEND_COMMUNICATION_RESOURCE_RESPONSE_REQUEST = '@@conversationLine/SEND_COMMUNICATION_RESOURCE_RESPONSE_REQUEST',
    PUBLIC_ATTACH_TO_RESOURCE_REQUEST = '@@conversationLine/PUBLIC_ATTACH_TO_RESOURCE_REQUEST'
}

export interface ConversationLinesFetchedData {
    OnConversationLinesFetchedForOrganisation: {
        ConversationLines: ConversationLine[];
    };
};

export interface ConversationLinesFetchedForOrganisationPayload {
    data: ConversationLinesFetchedData;
    pageSize: number;
    currentPage: number;
}